import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    private tabs$ = new Subject<any>();

    constructor() {
    }

    getTabsAsObservable() {
        return this.tabs$.asObservable();
    }

    pokeTabs(tabs) {
        this.tabs$.next(tabs);
    }


}
