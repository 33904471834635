import {Injectable} from '@angular/core';
import {environment as env} from '../../environments/environment';
import {SoapService} from '../services/soap/soap.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private catalogo = {};
  private estadosCiviles: any;

  constructor(private soapService: SoapService) {
  }

  get getCatalogo() {
    return this.catalogo && this.catalogo || JSON.parse(localStorage.getItem('catalogo'));
  }

  set setCatalogo(catalogo) {
    this.catalogo = catalogo;
    localStorage.setItem('catalogo', JSON.stringify(catalogo));
  }

  get getEstadosCiviles() {
    return this.estadosCiviles && this.estadosCiviles || JSON.parse(localStorage.getItem('estadosCiviles'));
  }

  set setEstadosCiviles(estadosCiviles) {
    this.estadosCiviles = estadosCiviles;
    localStorage.setItem('estadosCiviles', JSON.stringify(estadosCiviles));
  }

  /**
   * @method obtenerCatalogo
   * @description retorna los contactos de cada pais
   */
  obtenerCatalogo() {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
    };
    return this.soapService.post(url, 'sociedad_pais', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarEstadosCiviles(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad
    };
    return this.soapService.post(url, 'consulta_estado_civil', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarProvincias(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad
    };
    return this.soapService.post(url, 'consulta_provincias', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarAseguradoras(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad
    };
    return this.soapService.post(url, 'consultar_aseguradoras', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarFallas(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad
    };
    return this.soapService.post(url, 'consulta_fallas', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  validarAutopits(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad
    };
    return this.soapService.post(url, 'obtener_parametro_Cita_Ap', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarJustificaciones() {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
    };
    return this.soapService.post(url, 'obtener_justificaciones', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarMoneda(sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad
    };
    return this.soapService.post(url, 'obtiene_moneda_cliente_sociedad', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarCantones(sociedad, provincia) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad,
      id_provincia: provincia
    };
    return this.soapService.post(url, 'consulta_cantones', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarDistritos(sociedad, provincia, canton) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_sociedad: sociedad,
      id_provincia: provincia,
      canton
    };
    return this.soapService.post(url, 'consulta_distritos', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }
}
