<ion-content>
  <ion-item color="primary" class="header-help-section">
    <ion-icon (click)="goBack()" slot="start" class="bck-button-transparent" name="chevron-back" color="white">
    </ion-icon>
    <ion-chip color="primary" class="title-center">
      <img class="logo-seccion" [src]="logo" />
      <ion-label class="title-help">Términos y condiciones</ion-label>
    </ion-chip>
    <ion-chip slot="end" color="maincardsnavigation" outline="false">
      <ion-label></ion-label>
    </ion-chip>
  </ion-item>
  <ion-grid>
    <ion-row
      *ngIf="!authService.bs.isDesktop()"
      class="ion-margin-top ion-align-items-center"
    >
      <ion-col
        (click)="closeModal()"
        size="2"
        class="ion-text-center ion-no-padding"
      >
        <ion-icon
          class="bck-button"
          name="chevron-back"
          color="light"
        ></ion-icon>
      </ion-col>
      <ion-col size="10" class="fsz-14 ion-text-center">
        <ion-icon name="document" color="gptitle"></ion-icon>
        <ion-text class="ion-margin-start" color="gptitle"
          >Términos y Condiciones</ion-text
        >
      </ion-col>
    </ion-row>
    <div class="ion-padding ion-text-justify">
      <p>
        Todos los Miembros de MiGrupoQ, estarán sujetos a los siguientes
        términos y condiciones del programa:
      </p>

      <p>
        "Los presentes Términos y Condiciones del programa MiGrupoQ podrán ser
        modificados, corregidos y/o sustituidos en cualquier momento, con o sin
        previo aviso. La terminación del programa y/o los cambios, correcciones
        o modificaciones a los Términos y Condiciones del programa no darán
        derecho a ningún tipo de reclamo o indemnización por parte de Grupo Q.
      </p>

      <p>
        "MiGrupoQ" es el programa de beneficios (en adelante el "Programa") de
        Grupo Q Guatemala S.A., Grupo Q El Salvador S.A. de C.V., General de
        Vehículos S.A. de C.V., Grupo Q Honduras S.A., Grupo Q Nicaragua S.A. y
        Corporación Grupo Q Costa Rica S.A., (en adelante todas denominadas
        únicamente por Grupo Q) dirigido a los propietarios legales de vehículos
        nuevos de las marcas que Grupo Q representa en la región Centroamericana
        (en adelante los Miembros), quienes cumpliendo los términos y
        condiciones especificados en la presente tendrán acceso a la Membresía
        "MiGrupoQ" (en adelante la "Membresía"), mediante la cual podrán acceder
        a determinados beneficios que se conceden en primer lugar en Grupo Q y
        otros a través de los "Establecimientos Afiliados" que participen y se
        sujetan a los siguientes términos y condiciones (en adelante "Términos y
        Condiciones").
      </p>

      <ol class="first">
        <li>
          Afiliación al programa de beneficios
          <ol>
            <li>
              Es requisito indispensable para acceder al "Programa", i) Ser una
              persona natural propietaria de un vehículo nuevo adquirido en
              Grupo Q de uso particular ii) Ser el primer comprador del vehículo
              en Grupo Q iii) Que la fecha de adquisición no sea más de cuatro
              años desde la fecha de facturación, iv) Registrarse completando
              los datos en forma correcta, entera y veraz en el sitio web
              www.migrupoq.com y en la aplicación móvil MiGrupoQ, especialmente
              el correo electrónico, fecha de nacimiento, dirección y teléfono
              de contacto, v) En caso de existir copropiedad de una o más
              personas, solo podrá acceder al "Programa" quien figure como
              titular referente en los registros de Grupo Q.
            </li>

            <li>
              Personas jurídicas y otro tipo de entidades no son elegibles para
              obtener la "Membresía" y no podrán acceder a los beneficios que
              Grupo Q otorga dentro del "Programa".
            </li>

            <li>
              Actualmente la "Membresía" es de forma gratuita y sin costo para
              el "Miembro", sin embargo, Grupo Q se reserva el derecho de
              asignar en cualquier momento y a su entera discreción un cargo por
              la adquisición o renovación.
            </li>

            <li>
              La aceptación de los presentes "Términos y Condiciones" y sus
              actualizaciones futuras, se ejecutará mediante el uso del
              "Programa" y Beneficios" por parte de los Miembros
            </li>
          </ol>
        </li>
        <li>
          Membresía "MiGrupoQ"
          <ol>
            <li>
              Las "Membresías" concedidas identificarán al "Miembro" titular.
            </li>

            <li>
              Las "Membresías" y los beneficios; son personales,
              intransferibles, innegociables y para uso exclusivo de su titular,
              los beneficios se acreditarán con un documento válido de
              identificación del "Miembro".
            </li>

            <li>
              Las "Membresías" estar&aacute;n disponibles para el "Miembro" en
              formato electr&oacute;nico desde la aplicaci&oacute;n m&oacute;vil
              MiGrupoQ, &uacute;nicamente por solicitud del "Miembro" se
              proceder&aacute; a al impresi&oacute;n de la "Membresi&iacute;a"
              en formato f&iacute;sico y ser&aacute;n entregadas por correo
              postal a la direcci&oacute;n declarada por solicitud del
              "Miembro".
            </li>

            <li>
              Las "Membresías" no son tarjetas de crédito, ni de compra, ni de
              débito, no sirven como medio de pago y son de exclusiva propiedad
              de Grupo Q. Se exime de cualquier tipo de responsabilidad a Grupo
              Q por el uso distinto al aquí expuesto que se le dé a la tarjeta
              indicada, será única responsabilidad del titular de cada tarjeta
              la custodia, posesión y uso de la misma.
            </li>

            <li>
              Las "Membresías" tendrán una vigencia de un (1) año, desde la
              fecha de compra del vehículo con Grupo Q y/o afiliación, dicha
              vigencia podrá ser renovada al término, siempre y cuando el
              "Miembro" haya efectuado transacciones de compra en Grupo Q. La
              transacción se entenderá como la compra de vehículo, facturación
              de servicios de taller, repuestos y/o Autopits, pudiendo Grupo Q
              extender la vigencia si el "Miembro" cumple con los requisitos
              oportunamente dispuestos o a su criterio.
            </li>

            <li>
              El "Programa" cuenta con diferentes niveles de "Membres&iacute;a"
              a los que puede acceder el "Miembro", de acuerdo a los
              requerimientos establecidos por Grupo Q. Cada país podría tener
              incluso distintos niveles con beneficios especiales. Toda la
              información relacionada sobre los niveles y condiciones para cada
              uno de los beneficios puede ser consultada en www.migrupoq.com
            </li>
          </ol>
        </li>

        <li>
          Beneficios en Grupo Q
          <ol>
            <li>
              Los Miembros podrán acceder, durante la vigencia de la
              "Membresía", a beneficios en Grupo Q, otorgados a discreción y por
              el tiempo que Grupo Q considere a favor de los "Miembros " tales
              como descuentos en talleres y repuestos o bonificaciones por
              compra y para compra de vehículos nuevos. Estos beneficios serán
              diferenciados de acuerdo al nivel de la "Membresi&acute;a" del
              "Miembro". Estos "Beneficios" ser&aacute;n publicados
              oportunamente en www.migrupoq.com, comunicados a través de correo
              electrónico al "Miembro" o comunicados a través de Facebook
              MiGrupoQ al "Miembro". Dichos beneficios estar&aacute;n sujetos a
              disponibilidad, condiciones de uso y vencimiento que se detalle en
              cada caso y estarán disponibles en el país de origen de la compra
              del vehículo.
            </li>

            <li>
              El uso de los beneficios en Grupo Q deberá siempre ser realizado
              por el titular de la "Membres&iacute;a y para los veh&iacute;culos
              registrados a su nombre. De no ser as&iacute;, el beneficio no
              podrá ser utilizado.
            </li>

            <li>
              En ningún caso y en ninguna circunstancia estará permitido canjear
              beneficios por dinero en efectivo u otros bienes distintos.
            </li>
          </ol>
        </li>

        <li>
          Beneficios en "Establecimientos Afiliados"
          <ol>
            <li>
              Los Miembros podrán acceder, durante la vigencia de la
              "Membresía", a beneficios en la compra de productos o contratación
              de servicios en los "Establecimientos Afiliados" a este "Programa"
              que serán publicados oportunamente en www.migrupoq.com y estarán
              sujetos a disponibilidad, condiciones de uso y vencimiento que se
              detalle en cada caso.
            </li>

            <li>
              Existen beneficios otorgados a los Miembros, exclusivamente por
              los "Establecimientos Afiliados", al momento de la adquisición de
              un bien o contratación del servicio en dichos "Establecimientos".
              Los beneficios podrán consistir en regalos, descuentos variables
              en la adquisición de bienes o contratación de servicios, vendidos
              o provistos por los "Establecimientos Afiliados", los cuales serán
              informados oportunamente a los Miembrosa través del sitio web
              www.migrupoq.com y por cualquier otro medio que Grupo Q considere
              adecuado. En algunos casos se establecerá el límite disponible
              para utilizar el "Beneficio" por parte de los "Miembros. En
              consecuencia, Grupo Q, no se responsabiliza por la mala
              utilización o la no utilización de los beneficios.
            </li>

            <li>
              Los "Establecimientos Afiliados" son quienes ofrecen sus
              productos, prestaciones y/o servicios a través del sitio
              www.migrupoq.com. Las prestaciones, productos o servicios
              indicados en dicho sitio son responsabilidad exclusiva de los
              "Establecimientos Afiliados" indicados en la oferta y estarán
              sujetos a sus respectivos términos y condiciones, los cuales
              estarán indicados en dicho sitio web o señaladas en el mismo a
              través de un link. En consecuencia, Grupo Q no se responsabiliza
              de la cantidad, calidad y efectividad de los beneficios concedidos
              por los "Establecimientos Afiliados". Los aspectos relacionados a
              Garantías serán responsabilidad exclusiva de estos
              "Establecimientos Afiliados" quienes no son ni se podrán
              considerar empresas afines o de interés económico de esta.
            </li>

            <li>
              Las descripciones de los servicios, prestaciones y/o productos
              promocionados en www.migrupoq.com, en la aplicación MiGrupoQ,
              correo electrónico y Facebook MiGrupoQ, se realizan en base a la
              información proporcionada con anticipación por los
              "Establecimientos Afiliados". Las fotografías, imágenes y/o videos
              relativos a los servicios, prestaciones y/o productos son de
              carácter razonablemente ilustrativo. No se aceptará ningún
              reclamo, de ninguna naturaleza, fundado en supuestas diferencias
              entre dichas imágenes y los beneficios ya que es obligación del
              beneficiario el informarse o consultar cualquier duda que con
              relación al producto o servicio que se esté ofreciendo.
            </li>

            <li>
              En ningún caso y en ninguna circunstancia estará permitido canjear
              beneficios por dinero en efectivo u otros bienes distintos. Grupo
              Q no garantiza los productos y/o servicios objeto de los
              beneficios, ni se responsabiliza por la calidad ni ninguna
              cuestión relativa a dichos productos siendo el establecimiento
              afiliado y/o fabricante y/o prestador y/o importador de los mismos
              él único y exclusivo responsable por toda cuestión relativa a los
              mismos. En tal sentido cualquier reclamo respecto a los premios,
              deberá ser dirigido a sus prestadores, fabricantes, importadores
              y/o establecimiento afiliados.
            </li>
          </ol>
        </li>

        <li>
          Uso de las "Membresías" y accesos a los beneficios
          <ol>
            <li>
              Los Miembros podr&aacute;n aceder a los "Beneficios" presentando
              la "Membres&iacute;a" al momento de efectuar una compra y/o
              contratar un servicio en Grupo Q o en los "Establecimientos
              Afiliados", junto con la exhibición de un documento de identidad
              válido y previa validación de la "Membresía" de dichos
              "Establecimientos Afiliados". El acceso al "Beneficio" es
              aplicable a las formas de pago admitidas en las condiciones
              particulares de los beneficios, determinadas por el
              "Establecimientos Afiliados" y presentes en www.migrupoq.com.
            </li>

            <li>
              Para obtener el "Beneficio" otorgado por Grupo Q o por cada
              establecimiento afiliado, los Miembros deberán presentan la
              "Membresía" y documento de identidad antes de la emisión de la
              factura correspondiente.
            </li>

            <li>
              Los beneficios no podrán ser transferidos por los Miembros a
              terceros o a vehículos no registrados al "Miembro".
            </li>

            <li>
              Grupo Q podrá enviar ofertas y promociones especiales por medios
              electrónicos a los Miembros que hubieran proporcionado su
              dirección de correo electrónico y/o número de celular de forma
              valida. Grupo Q no garantiza que dicha información se presente
              siempre sin error, por lo que se reserva el derecho de corregir y
              notificar cualquier comunicación que hubiera sido enviada de forma
              errónea.
            </li>
          </ol>
        </li>

        <li>
          Cancelación
          <ol>
            <li>
              Grupo Q podrá cancelar o finalizar cualquier afiliación al
              "Programa" sin aviso previo y sin que ello genere derecho a
              reclamo o indemnización alguna por parte de los Miembros,
              incluyendo entre otros: a) Si no cumpliera con cualquiera de los
              "Términos y Condiciones"; b) Si abusare de cualquier privilegio
              concedido en el presente "Programa"; c) si proveyera cualquier
              información falsa a Grupo Q y/o a cualquier establecimiento
              afiliado; d) Si pretendiese vender a terceros los beneficios
              obtenidos u obtuviere "Beneficios" de manera indebida,
              contrariando los presentes "Términos y Condiciones"; e) Cualquier
              otro incumplimiento por parte del "Miembro" acerca del buen uso de
              la "Membresía" que defina Grupo Q.
            </li>

            <li>
              Grupo Q podrá dar por terminado el "Programa" en cualquier
              momento, notificando la decisión con un mínimo de treinta (30)
              días de anticipación a la fecha de terminación a través del envío
              de un e-mail a la dirección de correo electrónico registrado y/o
              por medio de una comunicación al domicilio que el "Miembro" haya
              establecido al momento de adherirse al "Programa" y/o que haya
              modificado con posterioridad y conste en el registro de Miembros,
              y/o en el sitio www.migrupoq.com y/o por cualquier medio masivo de
              comunicación, bastara cualquier de estos medios para considerarse
              como válida la notificación. Sin que este hecho genere para Grupo
              Q alguna responsabilidad.
            </li>
          </ol>
        </li>

        <li>
          Responsabilidad
          <ol>
            <li>
              Grupo Q pone a disposición de los Miembros un espacio virtual que
              permite la relación entre los Miembros y los "Establecimientos
              Afiliados" en la compra-venta de un contenido, producto y/o
              servicio. Grupo Q no es el propietario de los productos o
              servicios ofrecidos por los "Establecimientos Afiliados" y no
              tiene posesión de ellos. Grupo Q no se hace responsable de la
              existencia, calidad, estado e integridad de los bienes, productos
              o servicios ofrecidos en www.migrupoq.com. Cada establecimiento
              afiliado conoce y acepta ser el exclusivo responsable por las
              ofertas de los productos o servicios, debiendo el "Miembro"
              dirigirse directamente al establecimiento afiliado para realizar
              cualquier consulta y/o reclamo.
            </li>

            <li>
              Grupo Q no responde frente al "Miembro" por incumplimiento o
              calidad de las obligaciones del establecimiento afiliado a la hora
              de la prestación del servicio o entrega del producto.
            </li>

            <li>
              Grupo Q no se responsabiliza del mal uso que se realice de las
              aplicaciones o de los contenidos de su página web, recayendo
              únicamente la responsabilidad en aquel "Miembro" que haya
              realizado el mal uso.
            </li>

            <li>
              Grupo Q no asume responsabilidad alguna por la información
              contenida en la página web de terceros a los que se pueda acceder
              mediante enlaces (links) desde www.migrupoq.com, o por la
              información contenida en páginas web de terceros vinculada de
              algún modo a esta página web.
            </li>

            <li>
              Grupo Q se reserva el derecho a denegar o retirar el acceso a su
              página web, en cualquier momento y sin necesidad de preaviso, por
              iniciativa propia o a instancia de un tercero, a aquellos Miembros
              que violen la ley o que incumplan los presentes Términos y
              Condiciones Generales y/o lo dispuesto en las Condiciones
              Particulares y/o bien vulneren los derechos existentes sobre los
              contenidos, productos y/o servicios del presente sitio web.
            </li>

            <li>
              Grupo Q declina toda responsabilidad por cualquier interrupción de
              la página web www.migrupoq.com que se pudiese producir por la
              actualización o mejora de la misma o por causas ajenas.
            </li>

            <li>
              Los Miembros no podrán reclamar a Grupo Q., sus directivos,
              administradores, representantes, agencia de publicidad y
              empleados, por cualquier reclamo o demanda de terceros
              relacionados con las actividades promovidas dentro del sitio web o
              por el incumplimiento de las presentes Términos y Condiciones
              Generales y/o lo dispuesto en las Condiciones Particulares y demás
              normativas que se entienda incorporada al presente o por la
              violación de cualesquiera leyes o derechos de terceros.
            </li>
          </ol>
        </li>

        <li>
          Condiciones Particulares
          <ol>
            <li>
              En cualquier momento, Grupo Q podrá efectuar cambios, enmiendas,
              y/o sustitución en los presentes "Términos y Condiciones", a la
              denominación del "Programa" y a su logo, a los "beneficios" y
              ofertas incluidos en el "Programa" y a las condiciones de acceso a
              dichos "beneficios" y la vigencia de los mismos, así como en las
              características de los "Establecimientos Afiliados". Las
              modificaciones mencionadas precedentemente, podrán o no ser
              informadas al "Miembro" por cualquier medio de comunicación, a
              través de www.migrupoq.com o de cualquier otro que implique su
              difusión pública, a elección de Grupo Q.
            </li>

            <li>
              Los datos y ofertas relativos a los "beneficios" comunicados en el
              "Programa" revisten un carácter exclusivamente informativo y en
              modo alguno suponen que los "Establecimientos Afiliados",
              productos y/o servicios indicados, y la calidad de los mismos, son
              responsabilidad de Grupo Q. La información referida a los
              "Establecimientos Afiliados", incluidas sus características,
              marcas, logos y foto/s, es suministrada exclusivamente por cada
              uno de dichos "Establecimientos Afiliados", en consecuencia, Grupo
              Q no es responsable del contenido, o autenticidad o veracidad de
              dicha información.
            </li>

            <li>
              Los "Establecimientos Afiliados" no tienen la autoridad, expresa o
              implícita, para formular ninguna declaración, manifestación ni
              ofrecer garantías en nombre de Grupo Q o del "Programa", y en
              consecuencia ni Grupo Q ni el "Programa" asumen ninguna
              responsabilidad en relación a tales declaraciones, manifestaciones
              o garantías.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </ion-grid>
</ion-content>
