import {Injectable} from '@angular/core';
import {environment as env} from '../../environments/environment';
import {SoapService} from '../services/soap/soap.service';
import {Subject} from 'rxjs';
import {QCar} from '../models/global';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  response: any;
  url = env.API_ENDPOINT;
  url_reset_pass = env.API_ENDPOINT_PROD;
  codigo = env.WS_CODIGO;

  hayCitas: any;
  sinCitas: any;
  mensaje: any;

  appointments: any;
  $userAppts = new Subject<any>();

  constructor(public soapService: SoapService) {
  }


  pokeUserAppts(data) {
    this.$userAppts.next(data);
  }

  getUserApptsObservable() {
    return this.$userAppts.asObservable();
  }

  getUserAppts(): any {
    return this.appointments && this.appointments || JSON.parse(window.localStorage.getItem('userAppts')) || [];
  }

  setUserAppts(userAppts) {
    this.appointments = userAppts;
    window.localStorage.setItem('userAppts', JSON.stringify(userAppts));
  }

  getHistorial(sociedad, codigo_sap, inventario, placa = '') {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_sap,
      inventario,
      placa
    };
    // console.log('atributos');
    // console.log(atributos);
    return this.soapService.post(url, 'consulta_historial_vehiculo_2', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  // getHistorial(sociedad, codigo_sap, inventario, placa = '') {
  //   const url = `${this.url}`;
  //   const atributos = {
  //     cod: this.codigo,
  //     sociedad,
  //     codigo_sap,
  //     inventario
  //   };
  //   return this.soapService.post(url, 'consulta_historial_vehiculo', atributos)
  //     .then((result: any) => {
  //       return JSON.parse(result.return);
  //     }).catch(error => {
  //       return error;
  //     });
  // }

  getPaquetesCliente(sociedad, cliente_sap, kilometraje, LABVAL_TYPE, inventario) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      cliente_sap,
      kilometraje,
      LABVAL_TYPE,
      inventario
    };
    return this.soapService.post(url, 'consultar_paquetes_cliente', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  getTalleres(sociedad, paquete, danos, id_marca, tipoVhcle, vin) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      paquete,
      danos,
      id_marca,
      tipoVhcle,
      vin
    };
    return this.soapService.post(url, 'consultar_talleres', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  crearCitaTaller(sociedad, datos_cliente, datos_cita, contacto) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos_cliente,
      datos_cita,
      contacto
    };
    return this.soapService.post(url, 'crear_cita_taller', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  crearCitaAp(sociedad, datos_cliente, datos_cita, contacto) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos_cliente,
      datos_cita,
      contacto
    };
    return this.soapService.post(url, 'crear_cita_AP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerMantenimientosAutopits(sociedad, app) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      app
    };
    return this.soapService.post(url, 'consulta_mantenimentos_autopits', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerAutopits(sociedad) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad
    };
    return this.soapService.post(url, 'consultar_autopits', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerDisponibilidadTaller(sociedad, paquete, revision, detalle, otro, taller, value_sqc) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      paquete,
      revision,
      detalle,
      otro,
      taller,
      value_sqc
    };
    return this.soapService.post(url, 'seleccionar_disponibilidad_taller', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerDisponibilidadAp(sociedad, datos) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos
    };
    return this.soapService.post(url, 'seleccionar_disponibilidad_AP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerCitas(cliente_codigo) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      cliente_codigo
    };
    return this.soapService.post(url, 'obtener_citas', atributos)
      .then((result: any) => {
        // console.log('estamos en el result');
        // console.log(result);
        return JSON.parse(result.return);
      }).catch(error => {
        console.log('se recibio error');
        console.log(error);
        return error;
      });
  }

  eliminarCita(sociedad, cliente_codigo, num_cita, ap) {
    let url = `${this.url}`;
    let atributos = {
      cod: this.codigo,
      sociedad,
      cliente_codigo,
      num_cita,
      ap
    };
    return this.soapService.post(url, 'eliminar_cita', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  reprogramarCitaTaller(sociedad, codigo_cliente, num_cita, id_taller, fecha, hora) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_cliente,
      num_cita,
      id_taller,
      fecha,
      hora: hora + '00'
    };
    return this.soapService.post(url, 'repogramar_cita', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  reprogramarCitaAutopits(sociedad, codigo_cliente, num_cita, id_taller, fecha, hora, tipo_cliente, tipo_vhcle, cedula, inventario, contacto, origen) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_cliente,
      num_cita,
      id_taller,
      fecha,
      hora,
      tipo_cliente,
      tipo_vhcle,
      cedula,
      inventario,
      contacto,
      origen
    };
    return this.soapService.post(url, 'reprogramar_citaAP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  autenticarDatos(id_pais: string, sidchk: string, nmrchk: string, lnkchk: string) {
    const url = `${this.url_reset_pass}`;
    const atributos = {
      cod: this.codigo,
      id_pais: id_pais,
      sidchk: sidchk,
      nmrchk: nmrchk,
      lnkchk: lnkchk
    };
    return this.soapService.post(url, 'datos_restaurar_clave', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        console.log(error);
      });
  }

  restaurarClave(id_pais: string, codigo_cliente: string, tipo_cliente: string, clave: string, sidchk: string, nmrchk: string, lnkchk: string) {
    const url = `${this.url_reset_pass}`;
    const atributos = {
      cod: this.codigo,
      id_pais: id_pais,
      codigo_cliente: codigo_cliente,
      tipo_cliente: tipo_cliente,
      clave: clave,
      sidchk: sidchk,
      nmrchk: nmrchk,
      lnkchk: lnkchk
    };
    return this.soapService.post(url, 'restaurar_clave', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

}
