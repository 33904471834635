export default class QApp {
  logo: string;
  icon: string;
  title: string;
  text: string;
  icons: String[];
  buttonIcons: [{ icon: string }];
}

export interface QService {
  label: string;
  header?: string;
  classes?: string;
  img?: string;
  icon: string;
  url: string;
  action?: any;
  isSkeleton?: boolean;
}


export interface QPackage {
  isSelected: boolean; // Local field
  AWTYP: string;
  DETALLE: string;
  GUID: string;
  PACKAGE_ID: string;
  PACKAGE_ID_EXT: string;
  PACKAGE_TYPE: string;
  VALID_FROM: string;
  VALID_TO: string;
  OFERTA_SQC: string; // CONFIRM THIS PROP
}


export interface QWorkshop {
  tipo: string;
  id_cita_sap_taller: string;
  descripcion: string;
  canal: string;
  codigo_departamento_sap: string;
  department_name: string;
}

export class QUser {
  id_cliente: number;
  tipo_documento: string;
  numero_documento: string;
  codigo: string;
  nombre: string;
  FirstName: string;
  LastName: string;
  ultimo_acceso: string;
  telefono_habitacion: string;
  telefono_oficina: string;
  correo: string;
  celular: string;
  segmento: string;
  GenderCode: number;
  MaritalStatus: number;
  MaritalStatusName: string;
  MobileNumber: string;
  PhoneNumber: string;
  AddressHouseNumber: string;
  ContactPostalCode: string;
  AddressRegion: string;
  StreetName: string;
  grupo_de_cuenta: string;
  pais: string;
  sociedad: string;
  tipo: string;
  token: string;
  tipo_cliente_MDG: string;
  membresia?: any;
}

export class QUserProfileResponse {
  perfil: {
    SALVADOR: {
      id_cliente: string;
      codigo_cliente_fisico: string;
      codigo_sap: string;
      primer_nombre: string;
      segundo_nombre: string;
      tercer_nombre: string;
      primer_apellido: string;
      segundo_apellido: string;
      nombre_completo: string;
      fecha_nacimiento: string;
      sexo: string;
      tipo_documento: string;
      numero_documento: string;
      tipo_identificacion2: string;
      identificacion2: string;
      estado_civil: string;
      provincia: string;
      canton: string;
      distrito: string;
      casa: string;
      colonia: string;
      direccion_particular: string;
      nivel_academico: string;
      titulo: string;
      nivel_ingreso: string;
      fuente_ingreso: string;
      miembros_hogar: string;
      veh_hogar: string;
      telefono_habitacion: string;
      telefono_celular: string;
      telefono_oficina: string;
      correo_electronico: string;
      fax: string;
      canal_comunicacion: string;
      apartado_postal: string;
      documento_empresa2: string;
      hobbies: string;
      other_hobbie: string;
      id_cliente_sap: string;
      tipo_documento3: string;
      vip_descripcion: string,
      tipo_cliente: string;
      pais: string;
      sociedad: string;
    },
    GEVESA: {
      id_cliente: string;
      codigo_cliente_fisico: string;
      codigo_sap: string;
      primer_nombre: string;
      segundo_nombre: string;
      tercer_nombre: string;
      primer_apellido: string;
      segundo_apellido: string;
      nombre_completo: string;
      fecha_nacimiento: string;
      sexo: string;
      tipo_documento: string;
      numero_documento: string;
      tipo_identificacion2: string;
      identificacion2: string;
      estado_civil: string;
      provincia: string;
      canton: string;
      distrito: string;
      casa: string;
      colonia: string;
      direccion_particular: string;
      nivel_academico: string;
      titulo: string;
      nivel_ingreso: string;
      fuente_ingreso: string;
      miembros_hogar: string;
      veh_hogar: string;
      telefono_habitacion: string;
      telefono_celular: string;
      telefono_oficina: string;
      correo_electronico: string;
      fax: string;
      canal_comunicacion: string;
      apartado_postal: string;
      documento_empresa2: string;
      hobbies: string;
      other_hobbie: string;
      id_cliente_sap: string;
      tipo_documento3: string;
      vip_descripcion: string,
      tipo_cliente: string;
      pais: string;
      sociedad: string;
    },
    HONDURAS: {
      id_cliente: string;
      codigo_cliente_fisico: string;
      codigo_sap: string;
      primer_nombre: string;
      segundo_nombre: string;
      tercer_nombre: string;
      primer_apellido: string;
      segundo_apellido: string;
      nombre_completo: string;
      fecha_nacimiento: string;
      sexo: string;
      tipo_documento: string;
      numero_documento: string;
      tipo_identificacion2: string;
      identificacion2: string;
      estado_civil: string;
      provincia: string;
      canton: string;
      distrito: string;
      casa: string;
      colonia: string;
      direccion_particular: string;
      nivel_academico: string,
      titulo: string;
      nivel_ingreso: string,
      fuente_ingreso: string,
      miembros_hogar: string,
      veh_hogar: string,
      telefono_habitacion: string;
      telefono_celular: string;
      telefono_oficina: string;
      correo_electronico: string;
      fax: string,
      canal_comunicacion: string,
      apartado_postal: string,
      documento_empresa2: string;
      hobbies: string,
      other_hobbie: string,
      id_cliente_sap: string;
      tipo_documento3: string;
      mancomunada: string,
      vip_descripcion: string,
      tipo_cliente: string;
      pais: string;
      sociedad: string;
    }
  };
}

export class QCar {
  TIPO: string;
  BAPI: string;
  NUM_INVENTARIO: string;
  NUM_PLACA: string;
  ANIO_PLACA: string;
  TIPO_PLACA: string;
  CILINDRADA: string;
  KM_UM: string;
  MODELO: string;
  MARCA: string;
  COMBUSTIBLE: string;
  ESTILO: string;
  CODIGO_SAP_CLIENTE: string;
  TIPO_VEH: string;
  COD_TIPO_VEH: string;
  VIN: string;
  LABVAL_TYPE: string;
  DBM_LICEXT: string;
  ANO_VEH: number;
  LBRCAT: string;
  MFRNR: string;
  DESC_MODELO: string;
  VHCLE: string;
  SOCIEDAD: string;
  BD_QRM: string;
  NOM_CLIENTE: string;
  MATNR: string;
  MARK: string;
  LABVAL: string;
  CLASE_PED: string;
  KMS: number;
  ITOBJID: string;
  SPART: string;
  MAN: string;
  REP: string;
  WERKS: string;
  DBM_BUSTYPE: string;
  MATKL: string;
  COD_MARCA: string;
  PAQUETE: string;
  TIENE_SQC: string;
  VARIANTE: string;
  HEADER_GUID: string;
  VARIANT_GUID: string;
  VARIANTH_GUID: string;
  OFERTA_SQC: string;
  JOB_SQC: string;
  JOB_DESC: string;
  SIN_VEH: string;
  KUNNR: string;
  TIPO_VEHICULO: string;
  tiene_citas_ap: boolean;
  grupoq: string;
  recepcion: string;
  isGQ: boolean;
  isCQ?: boolean;
  isSQ?: boolean;
}

export const defaultCarProps = {
  TIPO: '',
  BAPI: '',
  NUM_INVENTARIO: '',
  NUM_PLACA: '',
  ANIO_PLACA: '',
  TIPO_PLACA: '',
  CILINDRADA: '',
  KM_UM: '',
  MODELO: '',
  MARCA: '',
  COMBUSTIBLE: '',
  ESTILO: '',
  CODIGO_SAP_CLIENTE: '',
  TIPO_VEH: '',
  COD_TIPO_VEH: '',
  VIN: '',
  LABVAL_TYPE: '',
  DBM_LICEXT: '',
  ANO_VEH: 0,
  LBRCAT: '',
  MFRNR: '',
  DESC_MODELO: '',
  VHCLE: '',
  SOCIEDAD: '',
  BD_QRM: '',
  NOM_CLIENTE: '',
  MATNR: '',
  MARK: '',
  LABVAL: '',
  CLASE_PED: '',
  KMS: 0,
  ITOBJID: '',
  SPART: '',
  MAN: '',
  REP: '',
  WERKS: '',
  DBM_BUSTYPE: '',
  MATKL: '',
  COD_MARCA: '',
  PAQUETE: '',
  TIENE_SQC: '',
  VARIANTE: '',
  HEADER_GUID: '',
  VARIANT_GUID: '',
  VARIANTH_GUID: '',
  OFERTA_SQC: '',
  JOB_SQC: '',
  JOB_DESC: '',
  SIN_VEH: '',
  KUNNR: '',
  TIPO_VEHICULO: '',
  tiene_citas_ap: false,
  grupoq: '',
  recepcion: '',
  isGQ: false,
  isCQ: true
};


export class LoanLight {
  numeroPrestam: number;
  fechaApertura: string;
  fechaUltimoPago: string;
  fechaProximoPago: string;
  fechaVencimiento: string;
  valorCuota: number;
  marca: string;
  modelo: string;
  anio_vehiculo: string;
  numeroPlaca: string;
  moneda: string;
  numeroMotor: string;
  numeroChasis: string;
  numeroPoliza: string;
  aseguradora: string;
  color: string;
  sociedadSAP: string;
}

export class Collecter{
  id: number;
  codigo: string;
  nombre: string;
  tipoCarnet: string;
}


export interface ISociety {
  PAIS: string;
  SOCIEDAD: string;
  COD_PAIS?: string;
}
