import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { WorkshopsHelper } from '../../helpers/workshops/workshopsHelper';
import { AuthService } from '../../providers/auth.service';
import { UtilService } from '../../providers/util.service';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  paisUsuario: any;
  geolocationUser: any;
  latitudActual: any;
  longitudActual: any;
  constructor(public authService: AuthService,
    public workshopHelper: WorkshopsHelper,
    public geolocation: Geolocation,
    public utilService: UtilService,
    public http: HttpClient) { }

  setearPais(pais: string = "SV") {
    window.localStorage.setItem("paisVisita", pais);
  }

  getPais() {
    return window.localStorage.getItem("paisVisita");
  }

  getCodePaisUsuario() {
    return this.authService.getPaisCodeUsuario().toUpperCase();
  }

  processCountry() {
    /* Cargando verificamos si tiene sesion iniciada y cambiamos el valor del pais segun usuario autenticado */
    if (this.authService.getProfile()) {
      this.paisUsuario = this.getCodePaisUsuario();
      this.workshopHelper.selectedCountry = this.paisUsuario;
      this.setearPais(this.paisUsuario);
      return;
    }
    this.obtenerPosicionActual();
    return;
  }

  /* Obtener longitud y latitud actual */
  obtenerPosicionActual() {
    return this.http.get("https://geolocation-db.com/json/").subscribe((arg: any) => {
      const country = arg.country_code;
      if (country) {
        this.workshopHelper.selectedCountry = this.paisPermitido(country);
        this.setearPais(this.paisPermitido(country));
      } else {
        this.obtenerPaisWithGPS();
      }
    }, err => {
      this.obtenerPaisWithGPS();
    })
  }

  obtenerPaisWithGPS() {
    this.geolocationUser = this.geolocation.getCurrentPosition().then((arg: Geoposition) => {
      this.latitudActual = arg.coords.latitude;
      this.longitudActual = arg.coords.longitude;
      const geocoder = new google.maps.Geocoder();
      let location;
      location = new google.maps.LatLng(this.latitudActual, this.longitudActual);
      geocoder.geocode({ location: location }, (result, status) => {
        this.processLocation(result);
      });
    }, err1 => {
      //this.utilService.presentToast("Para mostrar resultados de acuerdo a tu ubicación debes concedernos permisos.");
      this.workshopHelper.selectedCountry = "SV";
      this.setearPais();
    });
  }

  paisPermitido(pais: any) {
    let paises = ["SV", "CR", "NI", "HN", "GT"];
    if (paises.includes(pais)) {
      return pais;
    }
    return "SV";
  }

  /* Procesar ubicacion */
  processLocation(location) {
    if (location[1]) {
      for (var i = 0; i < location.length; i++) {
        if (location[i].types[0] === "locality") {
          let city = location[i].address_components[0].short_name;
          let state = location[i].address_components[2].short_name;
          let country = location[i].address_components[2].short_name;
          this.workshopHelper.selectedCountry = this.paisPermitido(country);
          this.setearPais(this.paisPermitido(country));
        }
      }
    }
  }

}
