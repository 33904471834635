import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy, Input,
} from '@angular/core';
import {ConferenceData} from '../../providers/conference-data';
import {NavController, Platform} from '@ionic/angular';
import {DOCUMENT} from '@angular/common';
import {environment as env} from '../../../environments/environment';

const GOOGLE_MAP_KEY = env.settings.map_api;

import HTMLMapMarker from '../../helpers/map.helper';

import {darkStyle} from './map-dark-style';
import {ThemeService} from '../../providers/theme.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {SmartHelper} from '../../helpers/smartq/SmartHelper';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {UtilService} from '../../providers/util.service';

@Component({
  selector: 'page-map',
  templateUrl: 'map.html',
  styleUrls: ['./map.scss'],
})
export class MapPage implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('mapCanvas', {static: true}) mapElement: ElementRef;

  darkTheme = false;
  businessLocationList: any;
  sucursalList: any;
  userLocation: any;
  @Input() backFn = undefined;
  gmaps: any;
  maps: any;
  backBtnSubscriber: Subscription;
  markersArray = [];
  currentMapTrack: any;
  currentPoint = null;
  showShareBtn = false;

  _unico: boolean = false;

  lastCurrentPoint: any;
  enlaceUbicacion: any;

  bounds: any;

  isDesktop = false;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private themeService: ThemeService,
    public confData: ConferenceData,
    private router: Router,
    private smartHelper: SmartHelper,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    public platform: Platform,
    private sharingSocial: SocialSharing,
    private clipboard: Clipboard,
    private utilService: UtilService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (this.router.getCurrentNavigation()) {
        const nav = this.router.getCurrentNavigation();
        if (nav.extras.state) {
          this.currentPoint = nav.extras.state.currentPoint;
        }
      }
    });

    this.isDesktop = this.platform.is('desktop');
  }

  ngOnInit() {
    // console.log('ngOnInit');
    this.initPage();
    this.darkTheme = this.themeService.darkMode;
    this.showShareBtn = false;
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
    this.showShareBtn = false;
  }

  initPage() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(
      999999,
      () => {
        return this.navCtrl.back({animated: true});
      }
    );
  }

  ionViewWillLeave() {
    this.bounds = null;
  }

  async ngAfterViewInit() {
    this.clearOverlays();
    const appEl = this.doc.querySelector('ion-app');
    const mapEle = this.mapElement.nativeElement;

    let style = [];
    // if (appEl.classList.contains('dark-theme')) {
    // if (this.darkTheme) {
    style = darkStyle;
    // }

    if (!this.gmaps) {
      this.gmaps = await this.getGoogleMaps(GOOGLE_MAP_KEY);
    }

    if (this.currentPoint) {
      await this.drawPoint(appEl, mapEle, style);
    } else if (this.smartHelper.roadList && this.smartHelper.roadList.length) {
      await this.drawRoad(appEl, mapEle, style);
    }

    this.gmaps.event.addListenerOnce(this.maps, 'idle', () => {
      mapEle.classList.add('show-map');

      if (this.bounds) {
        this.maps.setCenter(this.bounds.getCenter());
        this.maps.setZoom(this.getBoundsZoomLevel(this.bounds));
      }
    });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const el = mutation.target as HTMLElement;
          // this.darkTheme = el.classList.contains('dark-theme');
          if (this.maps && this.darkTheme) {
            this.maps.setOptions({styles: darkStyle});
          } else if (this.maps) {
            this.maps.setOptions({styles: []});
          }
        }
      });
    });
    observer.observe(appEl, {
      attributes: true,
    });
  }

  async drawPoint(appEl, mapEle, style) {
    const center = {
      name: 'Tú',
      lat: +this.currentPoint.latitud,
      lng: +this.currentPoint.longitud,
    };

    this.showShareBtn = true;

    if (!this.maps) {
      this.maps = new this.gmaps.Map(mapEle, {
        center: center,
        zoom: 12,
        styles: style,
      });
    }
    let user_marker_tpl = `<div class="marker"><div class="pin" style='background-color: #3730FF'></div>
				<div class="pin-effect" style='background-color: #3730ff'></div></div>`;

    // Set marker for user position
    const user_marker = new HTMLMapMarker({
      latlng: new google.maps.LatLng(
        +this.currentPoint.latitud,
        +this.currentPoint.longitud
      ),
      map: this.maps,
      html: user_marker_tpl,
    });
  }

  async drawRoad(appEl, mapEle, style) {
    const promises = await Promise.all(
      this.smartHelper.roadList.map((item) => {
        return {
          lat: +item.latitud,
          lng: +item.longitud,
        };
      })
    );

    this.smartHelper.roadList = await Promise.all(promises);

    const center = {
      name: 'Tú',
      lat: this.smartHelper.roadList[0].lat,
      lng: this.smartHelper.roadList[0].lng,
    };

    this.maps = new this.gmaps.Map(mapEle, {
      center,
      zoom: 11,
      styles: style,
    });

    let user_marker_tpl = `<div class="marker"><div class="pin" style='background-color: #43ff36'></div>
				<div class="pin-effect" style='background-color: #43ff36'></div></div>`;

    let user_marker_tpl_end = `<div class="marker"><div class="pin" style='background-color: #e62e1c'></div>
				<div class="pin-effect" style='background-color: #e62e1c'></div></div>`;

    // Set marker for user position
    const user_marker = new HTMLMapMarker({
      latlng: new google.maps.LatLng(
        this.smartHelper.roadList[0].lat,
        this.smartHelper.roadList[0].lng ||
        '',
      ),
      map: this.maps,
      html: user_marker_tpl
    });

    // Set marker for user position
    const user_marker_end = new HTMLMapMarker({
      latlng: new google.maps.LatLng(
        this.smartHelper.roadList[this.smartHelper.roadList.length - 1].lat,
        this.smartHelper.roadList[this.smartHelper.roadList.length - 1].lng ||
        '',
      ),
      map: this.maps,
      html: user_marker_tpl_end
    });

    google.maps.event.addListener(this.maps, 'bounds_changed', () => {
    });

    this.currentMapTrack = new google.maps.Polyline({
      path: this.smartHelper.roadList,
      geodesic: true,
      strokeColor: '#ff00ff',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    });
    // user_marker.setMap(this.maps);
    this.currentMapTrack.setMap(this.maps);

    // this.maps.setCenter(center);

    this.zoomToObject(this.currentMapTrack, this.maps);
  }

  clearOverlays() {
    // console.log('gonna clean markers');
  }

  getBoundsZoomLevel(bounds) {
    const m = document.querySelector('.map-canvas');
    if (m) {
      const mapDim = {
        width: m.clientWidth,
        height: m.clientHeight
      };
      var WORLD_DIM = {height: 256, width: 256};
      var ZOOM_MAX = 21;

      function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      var lngDiff = ne.lng() - sw.lng();
      var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

      var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    }
    return 12;
  }

  getGoogleMaps(apiKey: string): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve(googleModule.maps);
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.31`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        const googleModule2 = win.google;
        if (googleModule2 && googleModule2.maps) {
          resolve(googleModule2.maps);
        } else {
          reject('google maps not available');
        }
      };
    });
  }

  zoomToObject(obj, map) {
    const bounds = new google.maps.LatLngBounds(null);
    const points = obj.getPath().getArray();
    for (var n = 0; n < points.length; n++) {
      bounds.extend(points[n]);
    }
    this.bounds = bounds;
    this.maps.fitBounds(bounds);

  }

  shareLocationWhatsApp(data?: any) {
    this.lastCurrentPoint = this.currentPoint;
    this.enlaceUbicacion =
      'https://www.google.com/maps/search/' +
      this.lastCurrentPoint.latitud +
      ',+' +
      this.lastCurrentPoint.longitud;
    this.sharingSocial
      .shareViaWhatsApp(
        'Hey! Este es el último punto registrado de mi vehiculo ' +
        this.lastCurrentPoint.alias +
        '. Con dirección: ' +
        this.lastCurrentPoint.direccion,
        null,
        this.enlaceUbicacion
      )
      .then((result) => {
      })
      .catch((e) => {
      });
  }

  shareLocationFacebook(data?: any) {
    this.lastCurrentPoint = this.currentPoint;
    this.enlaceUbicacion =
      'https://www.google.com/maps/search/' +
      this.lastCurrentPoint.latitud +
      ',+' +
      this.lastCurrentPoint.longitud;
    this.sharingSocial
      .shareVia(
        'com.facebook.orca',
        'Hey! Este es el último punto registrado de mi vehiculo ' +
        this.lastCurrentPoint.alias +
        '. Con dirección: ' +
        this.lastCurrentPoint.direccion,
        'Ubicacion de mi Vehiculo',
        null,
        this.enlaceUbicacion
      )
      .then((result) => {
      })
      .catch((e) => {
      });
  }

  copyToClipboard() {
    this.lastCurrentPoint = this.currentPoint;
    this.enlaceUbicacion =
      'https://www.google.com/maps/search/' +
      this.lastCurrentPoint.latitud +
      ',+' +
      this.lastCurrentPoint.longitud;

    if (this.platform.is("desktop")) {
      navigator.clipboard.writeText(this.enlaceUbicacion).then(() => {
        this.utilService.presentToast('Coordenada copiada');
      }, (err) => {
        this.utilService.presentToast('Error al copiar coordenada');
      });
    } else {
      this.clipboard.copy(this.enlaceUbicacion).then(result => {
        this.utilService.presentToast('Coordenada copiada');
      }).catch(e => e);
    }

  }

}
