import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {HeaderComponent} from './header/header.component';
import {MainCarSelectorComponent} from './main-car-selector/main-car-selector.component';
import {MainNavigationComponent} from './main-navigation/main-navigation.component';
import {HeaderCarComponent} from './header-car/header-car.component';
import {CarEditOptionsComponent} from './car-edit-options/car-edit-options.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmergencyPopoverComponent} from './emergency-popover/emergency-popover.component';
import {AccountReportPopoverComponent} from './account-report-popover/account-report-popover.component';
import {ImagePreloadDirective} from '../directives/ImagePreload';
import {LoanEditOptionsPopoverComponent} from './loan-edit-options-popover/loan-edit-options-popover.component';
import {SpeedLimitComponent} from './speed-limit/speed-limit.component';
import {ActivateAlertComponent} from './activate-alert/activate-alert.component';
import {CommonComponentsModule} from './common/components.module';
import {ApptCompleteStatusComponent} from '../pages/migrupoq/appt/appt-new/appt-complete-status/appt-complete-status.component';
import {MapPage} from "../pages/map/map";
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {MultilineComponent} from './multiline/multiline.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MainCarSelectorComponent,
    MainNavigationComponent,
    HeaderCarComponent,
    CarEditOptionsComponent,
    EmergencyPopoverComponent,
    AccountReportPopoverComponent,
    LoanEditOptionsPopoverComponent,
    SpeedLimitComponent,
    ActivateAlertComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage
  ],
  entryComponents: [
    CarEditOptionsComponent,
    EmergencyPopoverComponent,
    AccountReportPopoverComponent,
    LoanEditOptionsPopoverComponent,
    SpeedLimitComponent,
    ActivateAlertComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CommonComponentsModule,
    NgxQRCodeModule
  ],
  exports: [
    HeaderComponent,
    MainCarSelectorComponent,
    MainNavigationComponent,
    HeaderCarComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage
  ]
})
export class ComponentsModule {
}

