export default class StorageHelper {
  constructor() {
  }

  isKey(key): boolean {
    return !!window.localStorage.getItem(key);
  }

  getItem(key) {
    return this.isKey(key) ? JSON.parse(window.localStorage.getItem(key)) : null;
  }

  setItem(key, value) {
    return window.localStorage.setItem(key, value);
  }

  isValidKeyETA(key, eta: number = 1) {
    if (!this.isKey(key)) {
      return false;
    }
    const d = new Date();
    const day = d.getDate();
    const init = this.isKey('fechaDeCarga') && parseInt(this.getItem('fechaDeCarga'), 0) || 0;
    const result = init - day;
    return result > 0 ? false : (result * -1) <= eta;
  }

  isValidInitKeyETA(key, eta: number = 1) {
    if (!this.isKey(key)) {
      return false;
    }
    if (this.getItem(key) == 'null') {
      return false;
    }
    const d = new Date();
    const day = d.getDate();
    const init = this.isKey('fbDate') && parseInt(this.getItem('fbDate'), 0) || 0;
    const result = init - day;
    return result > 0 ? false : (result * -1) <= eta;
  }


  checkLastUpdateMinutes(timeLimit = 20) {
    const lastTime = window.localStorage.getItem('appTime');
    if (!lastTime) {
      window.localStorage.setItem('appTime', (new Date().getTime()).toString());
      return true;
    } else {
      const restInTime = new Date().getTime() - new Date(+lastTime).getTime();
      let restInTimeMinutes = restInTime / 1000;
      restInTimeMinutes /= 60;
      restInTimeMinutes = Math.abs(Math.round(restInTimeMinutes));
      return restInTimeMinutes > timeLimit;
    }
  }
}
