import {HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {IonicModule} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from "./components/components.module";
import {HttpLink} from "apollo-angular/http";
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {CalendarModule} from "ion2-calendar";
import {MembershipModalComponent} from "./pages/membership/membership-modal/membership-modal.component";
import {NotificationsToastComponent} from "./components/notifications-toast/notifications-toast.component";
import {PreviewAnyFile} from "@ionic-native/preview-any-file/ngx";
import {BenefitsGraphqlService} from "./services/graphql/benefits-graphql.service";
import {CalendarService} from "ion2-calendar/dist/services/calendar.service";
import {Diagnostic} from "@ionic-native/diagnostic/ngx";
import {LocationAccuracy} from "@ionic-native/location-accuracy/ngx";
import {SocialSharing} from "@ionic-native/social-sharing/ngx";
import {PositionService} from "./providers/position.service";
import {Calendar} from "@ionic-native/calendar/ngx";
import {Device} from "@ionic-native/device/ngx";
import {HTTP} from "@ionic-native/http/ngx";
import {SoapService} from "./services/soap/soap.service";
import {FirebaseX} from "@ionic-native/firebase-x/ngx";
import {BarcodeScanner} from "@ionic-native/barcode-scanner/ngx";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {SentryIonicErrorHandler} from './handlers/SentryErrorHandler';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {UtilService} from './providers/util.service';
import {concat, InMemoryCache} from '@apollo/client/core';
import {errorHandler} from './providers/apollo-client';

import {AppVersion} from '@ionic-native/app-version/ngx';

import {Clipboard} from '@ionic-native/clipboard/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';

// Sentry.init({ dsn: 'https://148ed567806f4b09814698eba3307648@o359999.ingest.sentry.io/5469685' });
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    CalendarModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: false,
      mode: 'md'
    }),
    IonicStorageModule.forRoot(),
    SocialLoginModule
  ],
  declarations: [AppComponent, MembershipModalComponent, NotificationsToastComponent],
  providers: [
    PreviewAnyFile,
    BenefitsGraphqlService,
    SplashScreen,
    CalendarService,
    Diagnostic,
    Geolocation,
    LocationAccuracy,
    StatusBar,
    SocialSharing,
    PositionService,
    Calendar,
    Device,
    HTTP,
    // NativeKeyboard,
    SoapService,
    FirebaseX,
    NotificationsToastComponent,
    Facebook,
    BarcodeScanner,
    AppVersion,
    InAppBrowser,
    Clipboard,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: ErrorHandler, useClass: SentryIonicErrorHandler}, {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink, utilService: UtilService) => {
        return {
          cache: new InMemoryCache(),
          link: concat(errorHandler(utilService), httpLink.create({
            uri: environment.API_GRAPHQL_ENDPOINT,
          })),
        };
      },
      deps: [HttpLink, UtilService],
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('499589098031081')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  exports: [
    ComponentsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
