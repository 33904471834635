<div class="ion-text-center ion-padding pop-header">
  <ion-text>LLAMADA DE EMERGENCIA</ion-text>
</div>
<ion-list>

  <ion-row class="ion-padding">
    <ion-text>
      <small>SELECCIONA EL TIPO DE ASISTENCIA REQUERIDO</small>
    </ion-text>
  </ion-row>
  <ion-radio-group name="tipo" [(ngModel)]="tipo" value="biff">
    <ion-item>
      <ion-label>Choque</ion-label>
      <ion-radio slot="end" value="choque"></ion-radio>
    </ion-item>

    <ion-item>
      <ion-label>Asistencia Vial</ion-label>
      <ion-radio slot="end" value="asistencia"></ion-radio>
    </ion-item>
  </ion-radio-group>

  <!--Vehicle info-->
  <ion-grid>
    <div>
      <ion-row class="ion-padding-horizontal ion-padding-top">
        <ion-text>
          <small>Estos son los datos que debes proporcionar</small>
        </ion-text>
      </ion-row>
      <ion-item lines="none">
        <ion-label position="stacked">
          Vehículo:
        </ion-label>
        <span>{{selectedVehiculos?.MFRNR}}</span>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="stacked">Aseguradora:
        </ion-label>
        <span style="padding-top: 10px">{{vehicleInsurance?.objAseguradora?.nombre || 'N/D'}}</span>
      </ion-item>
      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-item lines="none">
            <ion-label position="stacked">
              Placa:
            </ion-label>
            <span style="padding-top: 10px">{{selectedVehiculos?.DBM_LICEXT || 'N/D'}}</span>
          </ion-item>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-item lines="none">
            <ion-label position="stacked">
              Poliza:
            </ion-label>
            <span style="padding-top: 10px">{{vehicleInsurance?.poliza || 'N/D'}}</span>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-list>

<ion-row>
  <ion-col class="ion-no-padding">
    <ion-button color="buttonaccept" class="ion-no-margin" (click)="llamar()" expand="full" fill="clear">Llamar
    </ion-button>
  </ion-col>
  <ion-col class="ion-no-padding">
    <ion-button (click)="dismissModal()" color="buttoncancel" class="ion-no-margin" expand="full" fill="clear">Cancelar
    </ion-button>
  </ion-col>
</ion-row>
