import { Injectable } from '@angular/core';
import SOAPClient from './soapclient.js';
import { environment as env } from '../../../environments/environment';
import { GeologoutService } from '../http/geologout.service.js';

@Injectable()
export class SoapService {

  public soapParams: any;
  public soapClient: any;

  constructor(private geologout: GeologoutService) {
  }

  post(url, action, params) {

    var sr = '<?xml version="1.0" encoding="utf-8"?>' +
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://rxmpws.com/">' +
      '<soap:Header>' +
      '<Authentication xmlns="http://domain.com/">' +
      '<Password>password</Password>' +
      '+<UserName>username</UserName>' +
      '</Authentication>' +
      '</soap:Header>' +
      '<soapenv:Body>' +
      '' +
      '</soapenv:Body>' +
      '</soapenv:Envelope>';


    this.soapParams = new SOAPClient.SOAPClientParameters;
    this.soapClient = SOAPClient.SOAPClient;
    return new Promise((resolve, reject) => {
      //Create SOAPClientParameters
      for (var param in params) {
        this.soapParams.add(param, params[param]);
      }
      //Create Callback
      var soapCallback = (e, status) => {
        if (e == null || e.constructor.toString().indexOf('function Error()') != -1) {
          reject('No se puede conectar con el servidor' + status);
        } else {
          if (action == 'recuperar_clave' || action == 'restaurar_clave' || action == 'datos_restaurar_clave' || action == 'activacion' || action == 'desligar_push' || params.clave || window.localStorage.getItem(env.production ? 'prod:pcode' : 'qa:pcode')) {
            resolve(e);
          } else {
            const imgs = localStorage.getItem('vehiclesImages');
            const fbDate = localStorage.getItem('fbDate');
            window.localStorage.clear();
            this.geologout.obtenerPosicionActual();
            localStorage.setItem('vehiclesImages', imgs);
            localStorage.setItem('fbDate', fbDate);
          }
        }
      };
      this.soapClient.invoke(url, action, this.soapParams, true, soapCallback);
    });
  }

  setCredentials(username, password) {
    this.soapClient.username = username;
    this.soapClient.password = password;
  }
}
