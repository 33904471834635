<ion-app [class.dark-theme]="false" [ngClass]="authService.appClass">

  <ion-menu *ngIf="authService.getToken()" contentId="content" menuId="content" side="end">
    <ion-content>
      <ion-list lines="none">
        <div class="ion-text-center">
          <img width="140px" src="assets/imgs/logo_home.png" alt="">
        </div>
        <ion-list-header>
          Menu
        </ion-list-header>
        <ion-menu-toggle autoHide="true" *ngFor="let p of appPages; let i = index">
          <ion-item [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
            <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
            <ion-label>
              {{p.title}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle autoHide="true">
          <ion-item (click)="logout()">
            <ion-icon slot="start" [name]="'power-outline'"></ion-icon>
            <ion-label>
              Cerrar Sesión
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>


    </ion-content>
  </ion-menu>

  <ion-router-outlet id="content"
                     style="pointer-events: auto !important; touch-action: auto !important;"></ion-router-outlet>


</ion-app>
