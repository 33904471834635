import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {QCar} from '../../models/global';
import {IonSlides, PopoverController} from '@ionic/angular';
import {PromisesService} from '../../providers/promises.service';
import {CarEditOptionsComponent} from '../car-edit-options/car-edit-options.component';
import {AuthService} from '../../providers/auth.service';
import {LoanHelper} from '../../helpers/crediq/LoanHelper';
import {IApps} from '../../interface/global';
import {LoanEditOptionsPopoverComponent} from '../loan-edit-options-popover/loan-edit-options-popover.component';
import {CatalogHelper} from '../../helpers/CatalogHelper';
import {VehicleHelper} from '../../helpers/VehicleHelper';
import {Subscription} from 'rxjs';
import {environment as env} from '../../../environments/environment';

@Component({
  selector: "app-main-car-selector",
  templateUrl: "./main-car-selector.component.html",
  styleUrls: ["./main-car-selector.component.scss"],
})
export class MainCarSelectorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() carList: QCar[] = [];
  @Input() showEditBtn = true;
  @Input() gpApp: IApps = "grupoq";
  @ViewChild("slider", { static: true }) public slideWithNav: IonSlides;
  @Output() onChangeCar = new EventEmitter<any>();
  @Input() swipeEnabled: boolean = true;

  @Input() currentCar: QCar = null;
  interval: any;
  interval2: any;
  ovw = "relative";
  maxRetry = 100;
  starRetry = 0;

  $globalVehicles: Subscription;

  vehiclesImages: any;

  imgStatues = [];

  dynamic = false;

  public slideOpts = {
    initialSlide: 0,
    preloadImages: true,
    updateOnImagesReady: true,
    grabCursor: true,
    pager: true,
    // observer: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    on: {
      beforeInit() {
        this.autoplay.stop();
        // console.log(this);
      },
    },
  };

  bullets: any;

  public ASSET_URL = env.SITE_URL + 'assets/vehiculos/';
  constructor(
    public authService: AuthService,
    private promiseService: PromisesService,
    private loanHelper: LoanHelper,
    public cdf: ChangeDetectorRef,
    private catalogHelper: CatalogHelper,
    private vehicleHelper: VehicleHelper,
    public popoverController: PopoverController
  ) {
    this.vehiclesImages = this.catalogHelper.getVehiclesImages();
  }

  async ngOnInit() {
    this.interval = window.setInterval(() => {
      this.dynamic = !this.dynamic;
    }, 1000);
  }

  async ngOnDestroy() {
    if (this.$globalVehicles) {
      this.$globalVehicles.unsubscribe();
    }
  }

  getAppIndex() {
    if (this.gpApp == "grupoq") {
      return this.promiseService.getUserCurrentVehicleIndex();
    }

    if (this.gpApp == "crediq") {
      return this.loanHelper.getUserCurrentVehicleIndex();
    }
  }

  ngAfterViewInit(): void {
    this.cdf.detectChanges();
  }

  async changeSlide() {
    // console.log('slide changed');
    const slideIndex = (await this.slideWithNav.getActiveIndex()) || 0;
    this.onChangeCar.emit({ index: slideIndex });
  }

  async editClicked(ev: any = null) {
    // console.log('presenting');
    this.authService.isAnyPopupActive = true;

    if (this.gpApp === "grupoq") {
      return this.migqPopover(ev);
    }

    if (this.gpApp === "crediq") {
      return this.crediqqPopover(ev);
    }
  }

  async presentPopover(ev = null) {
    // console.log('presenting');
    this.authService.isAnyPopupActive = true;
    return this.migqPopover(ev);
  }

  async migqPopover(ev) {
    const popover = await this.popoverController.create({
      component: CarEditOptionsComponent,
      cssClass: "my-custom-class",
      componentProps: {
        vehiculo: this.currentCar,
        insurance: this.promiseService.getInsuranceVehiclesCompanies(
          this.currentCar.NUM_PLACA
        ) || { aseguradora: "0" },
      },
      event: ev,
      translucent: true,
    });

    popover.onDidDismiss().then((data) => {
      this.authService.isAnyPopupActive = false;
    });
    return await popover.present();
  }

  async crediqqPopover(ev) {
    const popover = await this.popoverController.create({
      component: LoanEditOptionsPopoverComponent,
      cssClass: "my-custom-class",
      componentProps: {
        loan: this.loanHelper.getUserCurrentLoan(this.currentCar),
      },
      event: ev,
      translucent: true,
    });

    popover.onDidDismiss().then((data: any) => {
      this.authService.isAnyPopupActive = false;
      if (data.data.updated) {
        const newLoan = data.data.loan;
        let loanList = this.loanHelper.getUserLoans();
        loanList.detalleUS[this.loanHelper.getUserCurrentVehicleIndex()] =
          newLoan;
        this.loanHelper.setUserLoans(loanList);
        // this.loanHelper.pokeUserLoansDetail(loanList);
        this.loanHelper.setUserCurrentVehicle(
          newLoan,
          this.loanHelper.getUserCurrentVehicleIndex()
        );
        this.loanHelper.pokeUserCurrentVehicle(newLoan);
      }
    });
    return await popover.present();
  }

  getCarList() {
    return (this.carList.length && this.carList) || [];
  }
}
