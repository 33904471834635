import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {QCar} from '../../models/global';
import {UtilService} from '../../providers/util.service';
import {AuthService} from '../../providers/auth.service';
import {VehiculosService} from '../../providers/vehiculos.service';
import {PromisesService} from '../../providers/promises.service';
import {CatalogService} from '../../providers/catalog.service';
import {AlertController, PopoverController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {CatalogHelper} from '../../helpers/CatalogHelper';
import {VehicleHelper} from '../../helpers/VehicleHelper';

@Component({
  selector: 'app-car-edit-options',
  templateUrl: './car-edit-options.component.html',
  styleUrls: ['./car-edit-options.component.scss'],
})
export class CarEditOptionsComponent implements OnInit, OnDestroy {

  insurance: any = {aseguradora: ''};
  aseguradoras: any;
  @Input() vehiculo: QCar;
  @Output()
  onDeleteVehicle: EventEmitter<any> = new EventEmitter<any>();

  insuranceCompaniesVehiclesSubscription: Subscription;
  isInsuranceLoading = false;

  constructor(
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    public authService: AuthService,
    private promisesService: PromisesService,
    private zone: NgZone,
    private catalogService: CatalogService,
    private catalogHelper: CatalogHelper,
    private vehiclesService: VehiculosService,
    private vehicleHelper: VehicleHelper,
    private utilService: UtilService,
  ) {
    // console.log('getting data');
    // this.aseguradoras = JSON.parse(localStorage.getItem('aseguradoras'));
    if (this.promisesService.getLoadingInsuranceCompanies()) {
      this.utilService.presentLoading();
      this.isInsuranceLoading = true;
    }
  }


  ngOnInit() {
    this.aseguradoras = this.catalogHelper.getAseguradora(this.vehiculo.SOCIEDAD);

    this.insuranceCompaniesVehiclesSubscription = this.promisesService.getInsuranceCompaniesVehiclesObservable().subscribe((data) => {
      if (this.isInsuranceLoading) {
        this.insurance = this.promisesService.getInsuranceVehiclesCompanies(this.promisesService.getUserCurrentVehicle().NUM_PLACA);
        this.isInsuranceLoading = false;
        this.utilService.dismissLoading();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.insuranceCompaniesVehiclesSubscription) {
      this.insuranceCompaniesVehiclesSubscription.unsubscribe();
    }
  }

  async agregarAseguradora(vehiculo) {
    var poliza = this.insurance.poliza ? this.insurance.poliza : '';
    if (this.insurance.aseguradora == null || this.insurance.aseguradora == '' || this.insurance.aseguradora == '0') {
      this.utilService.presentToast('Debe seleccionar aseguradora');
    } else {
      await this.utilService.presentLoading();
      this.authService.agregarAseguradora(this.authService.getCodigoUsuario(), this.insurance.aseguradora, poliza, vehiculo.DBM_LICEXT, vehiculo.MFRNR, vehiculo.DESC_MODELO, vehiculo.VIN, vehiculo.MATKL, vehiculo.SOCIEDAD)
        .then(result => {
          this.utilService.dismissLoading();
          if (this.insurance.aseguradora == 0) {
            this.utilService.dismissLoading();
            this.utilService.presentToast('Desasociación de aseguradora realizada correctamente');
          } else {
            // console.log('asignando aseguradora');
            const aseguradoraObj = this.aseguradoras.find(x => x.id_lealtad_aseguradora == this.insurance.aseguradora);
            // console.log(this.insurance);
            // console.log(vehiculo);
            this.insurance.objAseguradora = aseguradoraObj;
            this.promisesService.setInsuranceVehiclesCompanies(vehiculo.NUM_PLACA, this.insurance);
            this.utilService.presentToast(result[0]);
          }
          this.dismissModal();
        }).catch(error => {
        this.utilService.dismissLoading();
        this.utilService.presentToast('Sin membresias adicionales');
      });
    }
  }


  async desasociarVehiculo(vehiculo) {
    const alert = await this.alertCtrl.create({
      header: 'DESASOCIAR VEHÍCULO',
      message: 'Seleccione el motivo:',
      inputs: [
        {
          type: 'radio',
          label: 'Cambio de vehículo de GQ',
          value: '1',
          checked: true
        },
        {
          type: 'radio',
          label: 'Cambio vehículo otra agencia',
          value: '2',
          checked: false
        },
        {
          type: 'radio',
          label: 'Robo',
          value: '3',
          checked: false
        },
        {
          type: 'radio',
          label: 'Pérdida Total',
          value: '5',
          checked: false
        },
        {
          type: 'radio',
          label: 'Otros',
          value: '4',
          checked: false
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        },
        {
          text: 'Confirmar',
          handler: data => {
            let descripcion;
            switch (data) {
              case '1':
                descripcion = 'Cambio de vehiculo de GQ';
                break;
              case '2':
                descripcion = 'Cambio vehiculo otra agencia';
                break;
              case '3':
                descripcion = 'Robo';
                break;
              case '5':
                descripcion = 'Perdida Total';
                break;
              default:
                descripcion = 'Otros';
            }
            this.procesoDesasociarVehiculo(vehiculo, data, descripcion);
          }
        }
      ]
    });
    await alert.present();
  }

  procesoDesasociarVehiculo(vehiculo, data, descripcion) {
    this.utilService.presentLoading();
    this.vehiclesService.desligarVehiculo(vehiculo.SOCIEDAD, vehiculo.VHCLE, data ? data : '', descripcion ? descripcion : '', 'Otro', this.authService.getCodigoUsuario(), vehiculo.DBM_LICEXT ? vehiculo.DBM_LICEXT : '', vehiculo.TIPO_PLACA, '', vehiculo.MFRNR, vehiculo.BAPI, '', '1')
      .then(result => {

        if (result[0].Error) {
          return this.utilService.presentToast(result[0].descripcion);
        }

        this.cargarVehiculosDespues(result);
      }).catch(error => {
      this.utilService.dismissLoading();
      this.utilService.presentToast('Error de conexion');
    });
  }

  cargarVehiculosDespues(resultado) {
    //this.vehiculos = [];
    this.vehiclesService.getVehiculosCliente(this.authService.getSociedadUsuario(), this.authService.getCodigoUsuario())
      .then(async (result) => {

        // console.warn('validando');

        const vehiculos = result;
        var d = new Date();
        var dia = d.getDate();
        localStorage.removeItem('vehiculos');
        localStorage.removeItem('fechaDeCarga');

        this.utilService.dismissLoading();

        // console.log('vehiculos despues');
        // console.log(result);

        localStorage.removeItem('fechaDeCarga');
        localStorage.setItem('fechaDeCarga', dia.toString());

        this.zone.run(() => {
          this.promisesService.setUserVehiclesBase(result);

          if (result && result.length > 0) {
            this.promisesService.setUserVehicles(result);
            this.promisesService.setUserCurrentVehicle(result[0], 0);
            // this.promisesService.pokeUserVehicles(result);
            this.vehicleHelper.pokeDeleteVehicle(result);
            this.onDeleteVehicle.emit(result);
          }
        });

        if (typeof resultado === 'string') {
          const confirm = await this.alertCtrl.create({
            header: 'Mensaje:',
            message: resultado,
            cssClass: 'gq-alert-unBoton',
            buttons: [
              {
                text: 'Aceptar'
              }
            ]
          });
          await confirm.present();
        } else {
          this.utilService.presentToast('No se puede desasociar');
        }
      }).catch(error => {
      this.utilService.dismissLoading();
      this.utilService.presentToast('Sin vehiculos asociados');
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }

}
