import StorageHelper from './StorageHelper';
import {Subject, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../providers/auth.service';
import {ServicesService} from '../providers/services.service';
import {UtilService} from '../providers/util.service';
import {QCar} from '../models/global';

@Injectable({
  providedIn: 'root'
})

export class ApptHistoryHelper extends StorageHelper {
  private loadingCarsApptHistory = false;
  existeError = false;
  error: any;
  existeHistorial = false;
  historial = [];
  $userCarHistory = new Subject<any>();

  private userCarHistory: any;
  public userCarHistoryStatus: any;
  public userCarHistoryError: any;
  public userCarHistoryErrorMsg: any;

  constructor(
    public authService: AuthService,
    private servicesService: ServicesService,
    public utilService: UtilService,
  ) {
    super();
  }

  setIsLoadingCarsHistory(status) {
    this.loadingCarsApptHistory = status;
  }

  setUserCarHistory(car, carHistory) {
    this.userCarHistory = carHistory;
    window.localStorage.setItem(`userCarHistory:${car.NUM_PLACA}`, JSON.stringify(carHistory));
  }

  getIsLoadingCarsHistory() {
    return this.loadingCarsApptHistory;
  }

  getUserCarHistoryObservable() {
    return this.$userCarHistory.asObservable();
  }

  pokeUserCarHistory(data) {
    this.$userCarHistory.next(data);
  }

  getUserCarHistory(NUM_PLACA): any {
    return JSON.parse(window.localStorage.getItem(`userCarHistory:${NUM_PLACA}`)) || [];
  }

  keyHistoryExist(NUM_PLACA) {
    return !!window.localStorage.getItem(`userCarHistory:${NUM_PLACA}`);
  }

  async loadCarHistory(car: QCar) {
    // console.log('obteniendo historial para 1');
    this.setIsLoadingCarsHistory(true);
    return this.servicesService.getHistorial(this.authService.getSociedadUsuario(), this.authService.getCodigoUsuario(), car.VHCLE, car.NUM_PLACA || '')
      .then(result => {
        this.setIsLoadingCarsHistory(false);
        if (result[0].Error) {
          console.log(result[0].Error);
          this.userCarHistoryError = true;
          this.userCarHistoryStatus = false;
          this.userCarHistoryErrorMsg = result[0].descripcion;
          this.setUserCarHistory(car, this.getUserCarHistory(car.VHCLE));
          this.pokeUserCarHistory(this.getUserCarHistory(car.VHCLE));
          // this.loadCarHistory(car);
        } else {
          this.userCarHistoryErrorMsg = '';
          this.userCarHistoryError = false;
          this.userCarHistoryStatus = true;
          this.setUserCarHistory(car, result);
          this.pokeUserCarHistory(result);
        }
      }).catch(error => {
        // console.log(error);
        this.setIsLoadingCarsHistory(false);
        // console.warn(error);
        this.utilService.presentToast('Problemas de conexión: HTY');
      });
  }
}
