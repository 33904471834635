import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AlertController } from "@ionic/angular";
import {AuthService} from '../../providers/auth.service';

@Injectable({
  providedIn: "root",
})
export class BenefitHelper {
  /* */
  $currentCountry = new Subject<any>();

  public selectedCountry = "CR";
  exclusiveList: any = [];

  constructor(private alertCtrl: AlertController, protected authService: AuthService) {}

  getCurrentCountryAsObservable() {
    return this.$currentCountry.asObservable();
  }

  pokeCurrentCountry(val: string) {
    this.$currentCountry.next(val);
  }

  getDataExclusive() {
    const localData = JSON.parse(localStorage.getItem('httpUserData'));
    if(!localData){
      return [];
    }
    this.exclusiveList = localData.data.exclusive_benefits;
    this.exclusiveList = [...this.exclusiveList].filter(x => x.pais.toUpperCase() == this.selectedCountry);
    return this.exclusiveList;
  }

  async presentAlertCheckbox() {
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: "Seleccionar País",
      inputs: [
        {
          name: "GT",
          type: "radio",
          label: "Guatemala",
          value: "GT",
          checked: this.selectedCountry === "GT",
        },
        {
          name: "SV",
          type: "radio",
          label: "El Salvador",
          value: "SV",
          checked: this.selectedCountry === "SV",
        },
        {
          name: "HN",
          type: "radio",
          label: "Honduras",
          value: "HN",
          checked: this.selectedCountry === "HN",
        },
        {
          name: "NI",
          type: "radio",
          label: "Nicaragua",
          value: "NI",
          checked: this.selectedCountry === "NI",
        },
        {
          name: "CR",
          type: "radio",
          label: "Costa Rica",
          value: "CR",
          checked: this.selectedCountry === "CR",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        {
          text: "Aceptar",
          handler: (data) => {
            this.selectedCountry = data;
            this.pokeCurrentCountry(data);
          },
        },
      ],
    });

    await alert.present();
  }
}
