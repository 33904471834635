import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './core/auth/auth.guard';
import {MembershipModalComponent} from './pages/membership/membership-modal/membership-modal.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id',
        loadChildren: () => import('./pages/home/home.module').then(m => m.FolderPageModule)
      }
    ]
  },
  {
    path: 'app-screen',
    children: [
      {
        path: ':app',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/app-screen/app-screen.module').then( m => m.AppScreenPageModule)
      }
    ],
  },
  {
    path: 'grupoq',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/migrupoq/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'appt-new',
        loadChildren: () => import('./pages/migrupoq/appt/appt-new/appt-new.module').then( m => m.ApptNewPageModule)
      },
      {
        path: 'appt-reschedule',
        loadChildren: () => import('./pages/migrupoq/appt/appt-reschedule/appt-reschedule.module').then( m => m.ApptReschedulePageModule)
      },
      {
        path: 'appt-history',
        loadChildren: () => import('./pages/migrupoq/appt/appt-history/appt-history.module').then( m => m.ApptHistoryPageModule)
      },
      {
        path: 'talleres',
        loadChildren: () => import('./pages/migrupoq/talleres/talleres.module').then( m => m.TalleresPageModule)
      },
    ],
  },
  {
    path: 'crediq',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/crediq/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'credit-cards',
        loadChildren: () => import('./pages/crediq/credit-cards/credit-cards.module').then( m => m.CreditCardsPageModule)
      },
    ],
  },
  {
    path: 'smartq',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/smartq/home/home.module').then( m => m.HomePageModule)
      },
      {
        path: 'commands',
        loadChildren: () => import('./pages/smartq/commands/commands.module').then( m => m.CommandsPageModule)
      },
      {
        path: 'routes',
        loadChildren: () => import('./pages/smartq/routes/routes.module').then( m => m.RoutesPageModule)
      },
      {
        path: 'alerts',
        loadChildren: () => import('./pages/smartq/alerts/alerts.module').then( m => m.AlertsPageModule)
      }
    ],
  },
  {
    path: 'my-coupons',
    loadChildren: () => import('./pages/my-coupons/my-coupons.module').then(m => m.MyCouponsPageModule)
  },
  {
    path: 'benefits/:country/:category',
    loadChildren: () => import('./pages/benefits/benefits.module').then( m => m.BenefitsPageModule)
  },
  {
    path: 'benefit-tabs',
    loadChildren: () => import('./pages/benefit-tabs/benefit-tabs.module').then( m => m.BenefitTabsPageModule)
  },
  {
    path: 'benefit-detail',
    loadChildren: () => import('./pages/benefit-detail/benefit-detail.module').then( m => m.BenefitDetailPageModule)
  },
  {
    path: 'emergency',
    loadChildren: () => import('./pages/emergency/emergency.module').then( m => m.EmergencyPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule)
  },
  {
    path: 'promotions-detail',
    loadChildren: () => import('./pages/promociones/promotions-details/promotions-details.module')
      .then( m => m.PromotionsDetailsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'forgot-passwd',
    loadChildren: () => import('./pages/forgot-passwd/forgot-passwd.module').then( m => m.ForgotPasswdPageModule)
  },
  {
    path: 'activate-account',
    loadChildren: () => import('./pages/activate-account/activate-account.module').then( m => m.ActivateAccountPageModule)
  },
  {
    path: 'membership',
    component: MembershipModalComponent
  },
  {
    path: 'currentmap',
    loadChildren: () => import('./pages/map/map.module')
      .then( m => m.MapModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/benefits-map/benefits-map.module')
      .then( m => m.BenefitsMapPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/web/landing/landing.module').then(m => m.LandingPageModule)
  },
  {
    path: 'web',
    loadChildren: () => import('./pages/web/home-web/home-web.module').then(m => m.HomeWebPageModule),
  },
  {
    path: 'eventos',
    loadChildren: () => import('./pages/eventos/eventos.module').then( m => m.EventosPageModule)
  },
  {
    path: 'events-details',
    loadChildren: () => import('./pages/eventos/events-details/events-details.module')
      .then( m => m.EventsDetailsPageModule)
  },
  {
    path: 'benefit-tabs',
    loadChildren: () => import('./pages/benefit-tabs/benefit-tabs.module').then( m => m.BenefitTabsPageModule)
  },
  {
    path: 'benefits-map',
    loadChildren: () => import('./pages/benefits-map/benefits-map.module').then( m => m.BenefitsMapPageModule)
  },
  {
    path: 'nearby',
    loadChildren: () => import('./pages/nearby/nearby.module').then( m => m.NearbyPageModule)
  },
  {
    path: 'favorite-details',
    loadChildren: () => import('./pages/favorite-details/favorite-details.module').then( m => m.FavoriteDetailsPageModule)
  },
  {
    path: 'benefits-businesses',
    loadChildren: () => import('./pages/benefits-businesses/benefits-businesses.module').then( m => m.BenefitsBusinessesPageModule)
  },
  {
    path: 'benefit-categories',
    loadChildren: () => import('./pages/benefit-categories/benefit-categories.module').then( m => m.BenefitCategoriesPageModule)
  },
  {
    path: 'destacado-detail',
    loadChildren: () => import('./pages/destacado-detail/destacado-detail.module').then( m => m.DestacadoDetailPageModule)
  },
  {
    path: 'my-coupons',
    loadChildren: () => import('./pages/my-coupons/my-coupons.module').then( m => m.MyCouponsPageModule)
  },
  {
    path: 'talleres',
    loadChildren: () => import('./pages/migrupoq/talleres/talleres.module').then( m => m.TalleresPageModule)
  },


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
