import StorageHelper from '..//StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../../providers/auth.service';
import {environment as env} from '../../../environments/environment';
import {Collecter, QCar} from '../../models/global';
import {UtilService} from '../../providers/util.service';
import {SmartService} from '../../providers/smartq/smart.service';
import {VehicleHelper} from '../VehicleHelper';
import {LoanHelper} from '../crediq/LoanHelper';
import {Device} from '@ionic-native/device/ngx';
import {LogService} from '../../providers/logging.service';

@Injectable({
  providedIn: 'root'
})

export class SmartHelper extends StorageHelper {
  private storageKeys = env.smartq.storagekeys;

  private alerts = [];

  private $alerts = new Subject<any>();
  private $performance = new Subject<any>();
  private $speedLimit = new Subject<any>();
  private isLoadingAlerts = false;
  private isLoadingRoad = false;

  private performance = {odometro: '', rendimiento: ''};
  private speedlimit = {velocidad: ''};

  public roadList = [];

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private smartService: SmartService,
    private loanHelper: LoanHelper,
    private device: Device,
    private vehicleHelper: VehicleHelper,
    protected logService: LogService
  ) {
    super();
  }

  setIsLoadingRoad(val: boolean) {
    this.isLoadingRoad = val;
  }

  getIsLoadingRoad() {
    return this.isLoadingRoad;
  }

  getAlertsAsObservable() {
    return this.$alerts.asObservable();
  }

  getPerformanceAsObservable() {
    return this.$performance.asObservable();
  }

  getSpeedLimitAsObservable() {
    return this.$speedLimit.asObservable();
  }

  pokeAlerts(val) {
    this.$alerts.next(val);
  }

  pokeSpeedLimit(val) {
    this.$speedLimit.next(val);
  }

  pokePerformance(val) {
    this.$performance.next(val);
  }

  getSmartQVehicles() {
    return [...this.loanHelper.mergeVehiclesLightApp()].filter((vehicle: QCar) => {
      // console.log(vehicle);
      return this.getLastPoint(vehicle);
    });
  }

  checkSmartQ(vehicles) {
    // console.log('verificando smart token');
    // console.log(window.localStorage.getItem(env.smartq.storagekeys.smartToken));

    if (!window.localStorage.getItem(env.smartq.storagekeys.smartToken)) {
      return this.smartService.login().then((data: any) => {
        if (data.hasOwnProperty('error')) {
          return console.log('error loging in SMARTQ');
        }
        window.localStorage.setItem(env.smartq.storagekeys.smartToken, data.token);
        vehicles.map((vehicle: QCar) => {
          this.getLastPointWS(vehicle);
        });
      });
    }
    vehicles.map((vehicle: QCar) => {
      this.getLastPointWS(vehicle);
    });
  }

  getVehiclePerformance(vehicle) {
    try {
      let cardLicence = vehicle.VIN;
      return this.getItem(env.production ? `dev:${this.storageKeys.performance}:${cardLicence}` : `prod:${this.storageKeys.performance}:${cardLicence}`) || this.performance;
    } catch (e) {
      // console.log(e);
      return this.performance;
    }
  }

  getVehiclePerformanceWS(vehicle, forced = false) {
    if (vehicle && vehicle.VIN) {
      let cardLicence = vehicle.VIN;
      if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.performance}:${cardLicence}` : `prod:${this.storageKeys.performance}:${cardLicence}`, 1) || forced) {
        this.smartService.getPerformance(vehicle).then((result: any) => {
          // console.log('smartq result performance');
          // console.log(result);

          if (!result.hasOwnProperty('error')) {
            this.setItem(env.production ? `dev:${this.storageKeys.performance}:${cardLicence}` : `prod:${this.storageKeys.performance}:${cardLicence}`, JSON.stringify(result.data));
          }

          this.pokePerformance(result.data);

        }).catch(error => {
          console.warn(error);
          this.utilService.presentToast('Problemas de conexión performance');
        });
      }
    }
  }

  getLastPoint(vehicle) {
    try {
      const cardLicence = vehicle.VIN;
      return this.getItem(env.production ? `dev:${this.storageKeys.vehicleFlag}:${cardLicence}` : `prod:${this.storageKeys.vehicleFlag}:${cardLicence}`);
    } catch (e) {
      // console.log(e);
    }
  }

  getSpeedLimit(vehicle) {
    try {
      let cardLicence = vehicle.VIN;
      return this.getItem(env.production ? `dev:${this.storageKeys.speedLimit}:${cardLicence}` : `prod:${this.storageKeys.speedLimit}:${cardLicence}`) || this.speedlimit;
    } catch (e) {
      // console.log(e);
      return this.speedlimit;
    }
  }

  getSpeedLimitWS(vehicle, forced = false) {
    if (vehicle && vehicle.VIN) {
      let cardLicence = vehicle.VIN;

      let deviceCompleto = JSON.stringify(this.logService.getLogInfo({description: `Obtener limite de velocidad`}));
      if (this.device.manufacturer == null && this.device.platform == null) {
        deviceCompleto = 'Web/Pruebas';
      }
      if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.speedLimit}:${cardLicence}` : `prod:${this.storageKeys.speedLimit}:${cardLicence}`, 1) || forced) {
        this.smartService.getVelocityLimit(vehicle, deviceCompleto).then((result: any) => {
          // console.log('smartq result speed limit');
          // console.log(result);
          if (!result.hasOwnProperty('error')) {
            this.setItem(env.production ? `dev:${this.storageKeys.speedLimit}:${cardLicence}` : `prod:${this.storageKeys.speedLimit}:${cardLicence}`, JSON.stringify(result.data));
          }
          this.pokeSpeedLimit(result.data);
        }).catch(error => {
          this.utilService.presentToast('Problemas de conexión speed');
        });
      }
    }
  }

  getRoad(vehicle, startDateTime, endDateTime) {
    let cardLicence = vehicle.VIN;
    return this.smartService.getRoadHistory(vehicle, startDateTime, endDateTime);
  }

  setSpeedLimit(vehicle, data) {
    let cardLicence = vehicle.VIN;
    this.setItem(env.production ? `dev:${this.storageKeys.speedLimit}:${cardLicence}` : `prod:${this.storageKeys.speedLimit}:${cardLicence}`, JSON.stringify(data));
  }

  getIsLoadingAlerts() {
    return this.isLoadingAlerts;
  }

  setIsLoadingAlerts(val: boolean) {
    this.isLoadingAlerts = val;
  }

  getLastPointWS(vehicle, force = false) {
    let cardLicence = vehicle.VIN;
    // console.log('checking car: ', cardLicence);

    if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.vehicleFlag}:${cardLicence}` : `prod:${this.storageKeys.vehicleFlag}:${cardLicence}`, 7) || force) {
    this.smartService.getlastPoint(vehicle).then((result: any) => {
      // console.log('smartq result last point');
      // console.log(result);

      if (!result.hasOwnProperty('error')) {
        this.setItem(env.production ? `dev:${this.storageKeys.vehicleFlag}:${cardLicence}` : `prod:${this.storageKeys.vehicleFlag}:${cardLicence}`, JSON.stringify(result.data[0]));
      }

    }).catch(error => {
      this.utilService.presentToast('Problemas de conexión last point');
    });
    }
  }

  getLastPointWSPromise(vehicle) {
    let cardLicence = vehicle.VIN;
    // console.log('checking car: ', cardLicence);

    return this.smartService.getlastPoint(vehicle);
  }

  getAlert(vehicle) {
    let cardLicence = vehicle.VIN;
    return this.getItem(env.production ? `dev:${this.storageKeys.alertHistory}:${cardLicence}` : `prod:${this.storageKeys.alertHistory}:${cardLicence}`) || null;
  }

  getAlertsWS(vehicle) {
    let cardLicence = vehicle.VIN;
    // console.log('checking car: ', cardLicence);

    this.isLoadingAlerts = true;
    this.smartService.getAlertsHistory(vehicle).then((result: any) => {
      // console.log('smartq alerts result');
      // console.log(result);
      if (!result.hasOwnProperty('error')) {
        this.setItem(env.production ? `dev:${this.storageKeys.alertHistory}:${cardLicence}` : `prod:${this.storageKeys.alertHistory}:${cardLicence}`, JSON.stringify(result.data));
        return this.pokeAlerts(result.data);
      }

      this.setItem(env.production ? `dev:${this.storageKeys.alertHistory}:${cardLicence}` : `prod:${this.storageKeys.alertHistory}:${cardLicence}`, JSON.stringify([]));
      return this.pokeAlerts([]);

    }).catch(error => {
      this.isLoadingAlerts = false;
      console.warn(error);
      this.utilService.presentToast('Problemas de conexión: SMH');
    });
  }

  saveSpeedLimitWS(limit, vehicle) {
    return this.smartService.setSpeedLimit(limit, vehicle);
  }

  activateAlert(vehicle, alertType) {
    return this.smartService.activateAlert(vehicle, alertType);
  }

  deactivateAlert(vehicle, alertType) {
    return this.smartService.deactivateAlert(vehicle, alertType);
  }

  getAlertTypes(vehicle) {
    const vin = vehicle.VIN;
    return this.smartService.getAlertTypes(vehicle);
  }
}
