import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';

import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';

import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {Storage} from '@ionic/storage';

import {UserData} from './providers/user-data';
import {AuthService} from './providers/auth.service';
import {CatalogService} from './providers/catalog.service';
import {UtilService} from './providers/util.service';
import {ServicesService} from './providers/services.service';
import {PromisesService} from './providers/promises.service';
import {VehiculosService} from './providers/vehiculos.service';
import {environment as env, environment} from '../environments/environment';
import {ApptHelper} from './helpers/ApptHelper';
import {ApptHistoryHelper} from './helpers/ApptHistoryHelper';
import {CatalogHelper} from './helpers/CatalogHelper';
import {AngularFireAuth} from '@angular/fire/auth';
import {LoanHelper} from './helpers/crediq/LoanHelper';
import {of, Subscription} from 'rxjs';
import {delay, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {VehicleHelper} from './helpers/VehicleHelper';
import {SmartService} from './providers/smartq/smart.service';
import {SessionService} from './providers/session.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {BaseService} from './services/http/base.service';
import {UpdateService} from './services/http/update.service';
import {BackbuttonService} from './services/http/backbutton.service';
import {PushNotificationService} from './providers/push-notification.service';
import {GeoService} from './services/http/geo.service';

declare let window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  backButton: Subscription;
  y: any;
  h: any;
  vh: any;
  offsetY: any;
  isAlertPresent = false;

  constructor(
    private menuCtrl: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    public apptHelper: ApptHelper,
    public apptHistoryHelper: ApptHistoryHelper,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userData: UserData,
    public authService: AuthService,
    private baseService: BaseService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,
    private servicioCatalogo: CatalogService,
    private servicioService: ServicesService,
    private promisesService: PromisesService,
    private vehiclesService: VehiculosService,
    private modalCtrl: ModalController,
    private utilService: UtilService,
    private catalogHelper: CatalogHelper,
    private vehicleHelper: VehicleHelper,
    public http: HttpClient,
    private fbAuth: AngularFireAuth,
    // CREDIQ
    private loanHelper: LoanHelper,
    // SMARTQ
    private smartService: SmartService,
    private sessionService: SessionService,
    private appVersion: AppVersion,
    private firebasex: FirebaseX,
    private updateService: UpdateService,
    private backButtonService: BackbuttonService,
    private pushNotificationService: PushNotificationService,
    private geoService: GeoService
  ) {
    this.fetchVehicles();
    this.initializeApp();
    // updateService.checkForUpdate();

    /* VERIFICACION DE CACHE REDIRECCION */
    backButtonService.execFunctionVerifyBackButtonCache();
    geoService.processCountry();
  }

  public appPages = [
    {
      title: 'Inicio',
      url: 'home/Index',
      icon: 'home',
      tabs: [],
    },
    {
      title: 'Perfil',
      url: '/profile',
      icon: 'person',
      tabs: [],
    },
    {
      title: 'Membresía',
      url: '/membership',
      icon: 'card',
      tabs: [],
    },
    {
      title: 'Beneficios',
      url: '/benefit-tabs',
      icon: 'pricetags',
      // tabs: ['benefits', 'services']
    },
    {
      title: 'Citas',
      url: 'grupoq/appt-reschedule',
      icon: 'calendar',
      // tabs: ['benefits', 'services']
    },
    {
      title: 'Talleres',
      url: 'grupoq/talleres',
      icon: 'car',
      // tabs: ['benefits', 'services']
    },
    /* {
      title: 'Mis cupones',
      url: '/my-coupons',
      icon: 'bookmark',
    }, */
    {
      title: 'Ayuda',
      url: '/help',
      icon: 'help-buoy',
      // tabs: ['benefits', 'services', 'help']
    },
  ];
  isLogged = false;

  dark = false;

  async ngOnInit() {
    this.menuCtrl.close('content');
    this.listenForLoginEvents();
    //console.log('firebase');
    this.initBackButton();

    // Was moved to login success
    /* this.initSmartQToken(); */

    // this.login();
  }

  ngAfterViewInit() {
    // this.logFbEvents(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP);
  }

  logFbEvents(name: string) {
    // this.platform.ready().then(() => {
    //   this.fb.logEvent(name, {isConnected: false})
    //     .then((m) => console.log('Facebook log: ' + m))
    //     .catch((e) => console.log('Facebook log error: ' + e));
    // });
  }

  ngOnDestroy() {
    // this.logFbEvents(this.fb.EVENTS.EVENT_NAME_DEACTIVATED_APP);
    this.backButton.unsubscribe();
  }

  initSmartQToken() {
    //console.log('pidiendto token smart');
    this.smartService.login().then((data: any) => {
      if (data.hasOwnProperty('error')) {
        return console.log('error loging in SMARTQ');
      }
      window.localStorage.setItem(
        environment.smartq.storagekeys.smartToken,
        data.token
      );
    });
  }

  initBackButton() {
    this.menuCtrl.close();
    this.backButton = this.platform.backButton.subscribeWithPriority(
      99999,
      async () => {
        // console.log('checking back button');
        if (await this.menuCtrl.isOpen()) {
          return this.menuCtrl.close();
        }

        if (this.router.url == '/home' && !this.authService.isAnyPopupActive) {
          return await this.presentAlertConfirm().then();
        } else {
          // try {
          //   this.navCtrl.back({animated: true});
          // } catch (e) {
          //   this.modalCtrl.dismiss({
          //     dismissed: true
          //   });
          // }
        }
      }
    );
  }

  async presentAlertConfirm() {
    if (!this.isAlertPresent) {
      const alert = await this.alertCtrl.create({
        header: 'Confirmar',
        message: '¿Desea salir de MiGQ?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.isAlertPresent = false;
            },
          },
          {
            text: 'Sí',
            handler: () => {
              this.isAlertPresent = false;
              // @ts-ignore
              navigator.app.exitApp();
            }
          }
        ]
      });

      await alert.present();
    }
  }

  /**
   * Check version to manage autologin
   */
  async checkVersionAppUpdate() {
    try {
      // console.log('Es una version diferente');
      this.utilService.presentLoading();

      const currentUsername = this.authService.getEmailUser();
      const currentPasswd = this.authService.getPasswdCrypted();

      // CLEAN OLD DATA
      this.sessionService.cleanSessionData();
      this.authService.pokeUserCrediQ({reset: true});

      // LOGIN
      const autologinResponse = await this.autoLoginPromise(
        currentUsername,
        currentPasswd
      );

      if (!autologinResponse) {
        return dispatchEvent(new CustomEvent('user:logout-web'));
      }

      this.authService.setToken(autologinResponse.usuario[0].token);
      this.authService.setPasswdCrypted(currentPasswd);

      this.authService.setProfile(autologinResponse.usuario[0]);
      this.authService.setSociedades(autologinResponse.sociedades);
      this.authService.pokeProfileDetail(autologinResponse.usuario[0]);

      this.vehicleHelper.getUserVehicles();

      this.utilService.dismissLoading();

      return dispatchEvent(new CustomEvent('user:login'));
    } catch (e) {
      return dispatchEvent(new CustomEvent('user:logout-web'));
    }
  }

  async autoLoginPromise(user?, passwd?) {
    return this.authService.login(
      user || this.authService.getEmailUser(),
      passwd || this.authService.getPasswdCrypted(),
      this.pushNotificationService.getFcmToken()
    );
  }

  async autoLogin() {
    this.authService.setIsRenewingToken(true);
    this.autoLoginPromise().then((data: any) => {
      try {
        this.authService.setIsRenewingToken(false);
        // console.log('renew token');
        this.authService.setToken(data.usuario[0].token);
        this.authService.pokeLogIn(true);
      } catch (e) {
        // console.log('error:');
        // console.warn(e);
      }
    });
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.setUpApp();
    });

    this.platform.resume.subscribe(async () => {
      this.setUpApp();
      // this.updateService.checkForUpdate();
    });
  }

  async setAppVersion() {
    // try {
    //   const appVersion = await this.appVersion.getVersionNumber();
    //   window.localStorage.setItem('appVersion', appVersion);
    // } catch (e) {
    //   console.log(e);
    // }
  }

  async setUpApp() {
    this.pushNotificationService.initPush();
    this.isLogged = !!this.authService.getToken();

    if (this.isLogged) {
      // Request new token for SQ
      this.initSmartQToken();

      // Version checker manager
      await this.setAppVersion;
      // if (!wl.getItem('appVersion') || wl.getItem('appVersion') !== appVersion) {
      //   wl.setItem('appVersion', appVersion);
      //   return await this.checkVersionAppUpdate();
      // }

      this.promisesService.setIsLoadingCarsHistory(true);

      // this.pushNotificationService.initPush();
      // this.pushNotificationService.getWsProfileClient();

      /**
       * ASYNC DATA FOR CREDIQ
       * Try to login on crediq
       * **/
      this.loanHelper.loginGqCrediQWS();

      if (this.authService.isCrediQUser()) {
        this.loanHelper.getloanDetailsLightWS(true); // loan details light (to load vehicles faster)
        // this.collectHelper.getCollectersWS(true); // get collectors catalog
        this.loanHelper.getuserCardsWS(true); // get user cc
        this.loanHelper.getloanDetailsWS(); // loan details full (to load full loan details)
      }

      this.getUserProfile();
      this.getUserVehicles();
      // Load memberships
      this.cargarMembresias();

      // Reload token exp
      this.autoLogin();

      // Async load base data
      this.apptHelper.getCitas();
      this.catalogHelper.cargarEstadosCiviles();
      this.catalogHelper.cargarProvincias();
      this.catalogHelper.cargarMoneda();
      this.catalogHelper.cargarFallas();
      this.catalogHelper.cargarJustificaciones();
      this.catalogHelper.procesoCargarCatalogo();
    }
  }

  tapCoordinates(e) {
    this.y = e.touches[0].clientY;
    this.h = window.innerHeight;
    this.vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    // console.log('valor en y');
    // console.log(this.y);
    // console.log('valor de vh');
    // console.log(this.vh);

    this.offsetY = this.h - this.y;
    // console.log('offset = ' + this.offsetY);
    this.offsetY = (this.h - this.y);
    // console.log('offset = ' + this.offsetY);
  }

  keyboardShowHandler(e) {
    if (this.router.url == '/login') {
      return;
    }

    // const kH = e.keyboardHeight;
    const kH = this.vh / 2;
    // console.log(e.keyboardHeight);
    // console.log('off');
    // console.log(this.offsetY);
    const middle = this.vh / 2;

    // if (this.y > (middle + 20)) {
    //   console.log('click un poco mas alla de la mitad');
    //
    //   const marginTop = ((this.vh / 2) - 20) * -1 + 'px';
    //
    //   if (this.y > (middle + 60)) {
    //
    //   }
    //
    //
    //   const bodyMove = document.querySelector('.ion-page:not(.ion-page-hidden) > ion-content') as HTMLElement,
    //     bodyMoveStyle = bodyMove.style;
    //   const bodyMoveModal = document.querySelector('ion-modal.show-modal ion-content') as HTMLElement;
    //   const bodyMoveModalStyle = bodyMoveModal && bodyMoveModal.style || null;
    //
    //   bodyMoveStyle.top = marginTop;
    //
    // }
    const bodyMove = document.querySelector('.ion-page:not(.ion-page-hidden) > ion-content') as HTMLElement,
      bodyMoveStyle = bodyMove.style;
    const bodyMoveModal = document.querySelector('ion-modal.show-modal ion-content') as HTMLElement;
    const bodyMoveModalStyle = bodyMoveModal && bodyMoveModal.style || null;
    // console.log('calculating ' + kH + '-' + this.offsetY + '=' + (kH - this.offsetY));

    if (this.offsetY < kH + 40) {
      bodyMoveStyle.bottom = (kH - this.offsetY + 30) + 'px';
      if (bodyMoveModalStyle) {
        bodyMoveModalStyle.bottom = (kH - this.offsetY + 30) + 'px';
      }
      // bodyMoveStyle.top = 'initial';
    }
  }

  keyboardHideHandler() {
    // console.log('gone');
    const removeStyles = document.querySelector('.ion-page:not(.ion-page-hidden) > ion-content') as HTMLElement;
    const removeStyles2 = document.querySelector('ion-modal.show-modal ion-content') as HTMLElement;
    removeStyles.removeAttribute('style');
    if (removeStyles2) {
      removeStyles2.removeAttribute('style');
    }
  }

  listenForLoginEvents() {
    /**
     *
     * Temporal fix for keyboard over inputs
     * https://forum.ionicframework.com/t/keyboard-hides-input-until-i-start-typing/60827/27
     *
     * **/
    window.addEventListener('touchstart', this.tapCoordinates.bind(this));

    window.addEventListener('keyboardWillShow', (e) => {
      console.log('Keyboard will Show');
      this.keyboardShowHandler(e);
    });
    window.addEventListener('keyboardDidShow', () => {
      console.log('Keyboard is Shown');
    });
    window.addEventListener('keyboardWillHide', () => {
      console.log('Keyboard will Hide');
      // document.querySelector('ion-content').classList.remove('showKeyboard');
      this.keyboardHideHandler();
    });
    window.addEventListener('keyboardDidHide', () => {
      console.log('Keyboard is Hidden');
    });

    window.addEventListener('user:login', () => {
      this.logInHook();
    });

    window.addEventListener('user:login-web', () => {
      this.logInHook('web/home');
    });

    window.addEventListener('user:logout', async () => {
      this.logOutHook();
    });

    window.addEventListener('user:logout-web', async () => {
      this.logOutHook('/landing');
    });

    window.addEventListener('user:login', async () => {
      this.logInHook();
    });

    window.addEventListener('user:logout', async () => {
      this.menuCtrl.close('content');

      this.smartService.cleanSafePropsFromStorage();
      this.initSmartQToken();

      this.sessionService.cleanSessionData();

      this.catalogHelper.cargarImagenesVehiculos();

      this.promisesService.pokeUserVehicles([]);
      this.promisesService.pokeUserCurrentVehicle(null);
      this.vehicleHelper.pokeCurrentVehicle(null);
      this.vehicleHelper.pokeVehicles([]);

      await this.utilService.presentLoading();
      await delay(2000);
      await this.utilService.dismissLoading();
      return this.router.navigateByUrl('/login');
    });
  }

  logInHook(url = 'home/Index') {
    // debugger;
    // Save time for login
    window.localStorage.setItem('appTime', new Date().getTime().toString());

    // Version checker manager
    // await this.setAppVersion;

    /** ASYNC DATA FOR CREDIQ **/
    this.loanHelper.loginGqCrediQWS();

    // SQ LOGIN
    this.initSmartQToken();

    /** TODO: DO Cronos Login */
      // Profile
    const userData = this.authService.getProfile();
    const pwdSalt = this.authService.getPasswd();
    const formData = new FormData();

    formData.append('id_cliente', userData.id_cliente.toString());
    formData.append('codigo', userData.codigo.toString());
    formData.append('numero_documento', userData.numero_documento.toString());
    formData.append('tipo_documento', userData.tipo_documento.toString());
    formData.append('nombre', userData.nombre.toString());
    formData.append('pais', this.authService.getPaisCodeUsuario());
    formData.append('sociedad', userData.sociedad.toString());
    formData.append('tipo', userData.tipo.toString());
    formData.append('salt', pwdSalt.toString());
    formData.append('correo', userData.correo.toString());
    formData.append('celular', userData.celular.toString());

    /* console.log("FORM DATA LOGIN CRONOS : ", userData);
    console.log("PAIS DATA LOGIN CRONOS : ", this.authService.getPaisCodeUsuario());
    console.log("PWD SALT : ", formData.getAll("sociedad")); */

    // check if is new installation
    // if (this.authService.isFirstInstall()) {
    //   formData.append('is_new_install', '0');
    //   // formData.append('is_new_install', '1');
    //
    //   // Append device data
    //
    // }
    this.baseService.postLoginHttp(formData).subscribe((data) => {
      window.localStorage.setItem('httpUserData', JSON.stringify(data));
      // this.authService.setFirstInstall('0');
    }, error => {
      console.log("DATA ERROR : ", error, "DATOS ENVIADOS : ", formData);
    });

    this.authService.pokeLogIn(this.authService.getProfile());

    // Load User Profile
    this.getUserProfile();

    // Load memberships
    this.cargarMembresias();

    // Async load base data
    this.apptHelper.getCitas();
    this.catalogHelper.cargarEstadosCiviles();
    this.catalogHelper.cargarProvincias();
    this.catalogHelper.cargarMoneda();
    this.catalogHelper.cargarFallas();
    this.catalogHelper.cargarJustificaciones();
    this.catalogHelper.procesoCargarCatalogo();

    return this.router.navigateByUrl(url);
  }

  async logOutHook(url = '/login') {

    try {
      this.authService.logout(false);
    } catch (e) {
      console.log(e);
    }

    this.smartService.cleanSafePropsFromStorage();
    this.initSmartQToken();

    this.sessionService.cleanSessionData();

    this.catalogHelper.cargarImagenesVehiculos();

    this.promisesService.pokeUserVehicles([]);
    this.promisesService.pokeUserCurrentVehicle(null);
    this.vehicleHelper.pokeCurrentVehicle(null);
    this.vehicleHelper.pokeVehicles([]);

    // await this.utilService.presentLoading();
    await delay(3000);
    // await this.utilService.dismissLoading();
    return this.router.navigateByUrl(url);
  }

  logout() {
    this.authService.logout();
    //window.dispatchEvent(new CustomEvent('user:logout-web'));
  }

  cargarMembresias() {
    // console.log('loading memberships');
    this.authService.cargarMembresias(this.authService.getCodigoUsuario())
      .then(result => {
        // console.log('memberships result');
        // console.log(result);
        this.authService.setMemberships(result[this.authService.getSociedadNombrePaisUsuario()].membresias);
      }).catch(error => {
      console.log('Sin membresias adicionales');
      this.authService.setMemberships([]);
    });
  }

  getUserProfile() {
    // console.log('cargando perfil');
    const profileData = this.authService.getProfileDetail();
    if (profileData) {
      this.authService.pokeProfileDetail(profileData);
    } else {
      console.log('perfil from ws');
      this.authService
        .consultaPerfil(
          this.authService.getCodigoUsuario(),
          this.authService.getSociedadUsuario(),
          this.authService.getProfile().numero_documento,
          this.authService.getProfile().tipo_cliente_MDG
        )
        .then((result: any) => {
          // console.log('result from ws');
          // console.log(result);
          // const profile = result.perfil[this.authService.getPaisUsuario().toUpperCase()];
          const profile = result.datos[0];
          // console.warn(profile);
          // profile = result.perfil[this.usuario[0].pais.toUpperCase()];
          const date = new Date(profile.fecha_nacimiento);
          const nuevaDate =
            date.getFullYear() +
            '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + date.getDate()).slice(-2);

          const address = result.direcciones && result.direcciones.find(x => x.principal === 'S');
          const phone = result.telefonos && result.telefonos.find(x => x.principal === 'S');
          const correo = result.correos && result.correos.find(x => x.principal === 'S');
          const contactos = result.contactos;

          const profileObj = {
            // Main Data
            // fechaNacimiento: nuevaDate,
            codigo_cliente_fisico: profile.codigo_cliente_fisico,
            vip_descripcion: profile.vip_descripcion,
            tipo_documento: profile.tipo_documento,
            numero_documento: profile.numero_documento,
            contactos: contactos,
            primer_nombre: profile.primer_nombre,
            segundo_nombre: profile.segundo_nombre,
            primer_apellido: profile.primer_apellido,
            segundo_apellido: profile.segundo_apellido,
            apellido_casada: profile.apellido_casada,
            sexo: profile.sexo,
            provincia: profile.provincia,
            fecha_nacimiento: profile.fecha_nacimiento,
            estado_civil: profile.estado_civil,
            canton: profile.canton,
            distrito: profile.distrito,
            barrio: profile.barrio,
            telefono_celular: profile.telefono_celular,
            telefono_habitacion: profile.telefono_habitacion,
            correo_electronico: profile.correo_electronico,
            tipo_identificacion2: profile.tipo_identificacion2,
            identificacion2: profile.identificacion2,
            tipo_doc3: profile.tipo_doc3,
            documento_empresa2: profile.documento_empresa2,
            id_cliente: profile.id_cliente,
            extranjero: profile.extranjero,
            grupo_de_cuenta: profile.grupo_de_cuenta,
            tipo_documento_pais: profile.tipo_documento_pais,
            tipo_identificacion2_pais: profile.tipo_identificacion2_pais,
            tipo_doc3_pais: profile.tipo_doc3_pais,
            expedicion_documento: profile.expedicion_documento,
            tipo_cliente: profile.tipo_cliente,
            codigo_cliente: profile.codigo_cliente,

            // Addresses
            ...(address && {
              address: {
                new_direcion_id: address.new_direcion_id,
                id_direccion_cliente: address.id_direccion_cliente,
                id_form: address.id_form,
                fk_id_cliente: address.fk_id_cliente,
                fk_codigo_cliente: address.fk_codigo_cliente,
                fk_id_direccion_tipo: address.fk_id_direccion_tipo,
                nombre_direccion: address.nombre_direccion,
                provincia: address.provincia,
                canton: address.canton,
                distrito: address.distrito,
                barrio: address.barrio,
                direccion: address.direccion,
                colonia: address.colonia,
                numero_casa: address.numero_casa,
                telefono_celular: address.telefono_celular,
                telefono_habitacion: address.telefono_habitacion,
                correo_electronico: address.correo_electronico,
                estado: address.estado,
                principal: address.principal,
                dirPrincipal: address.dirPrincipal,
                estandar: address.estandar,
                tipo_cliente: address.tipo_cliente,
                celular_contacto: address.celular_contacto,
                telefono_empresa: address.telefono_empresa,
                mdg_id: address.mdg_id,
                punto_referencia: address.punto_referencia,
                id_canton: address.id_canton,
                id_distrito: address.id_distrito,
                fk_tipo_comunicacion: address.fk_tipo_comunicacion,
                mdg_id_dir: address.mdg_id_dir,
              },
            }),

            // Phones
            ...(phone && {
              phone: {
                id: phone.id || '',
                valor: phone.valor || '',
                tipo: phone.tipo || '',
                tipo_cliente: phone.tipo_cliente || '',
                id_direccion_cliente: phone.id_direccion_cliente || '',
                fk_id_direccion_tipo: phone.fk_id_direccion_tipo || '',
                principal: phone.principal || '',
                consecutivo_mdg: phone.consecutivo_mdg || '',
                mdg_id: phone.mdg_id || '',
                codigo_mdg: phone.codigo_mdg || '',
              }
            }),

            // Correos
            ...(correo && {
              correo: {
                codigo_mdg: correo.codigo_mdg || '',
                consecutivo_mdg: correo.consecutivo_mdg || '',
                fk_id_direccion_cliente: correo.fk_id_direccion_cliente || '',
                fk_id_direccion_tipo: correo.fk_id_direccion_tipo || '',
                id: correo.id || '',
                idQRM: correo.idQRM || '',
                mdg_id: correo.mdg_id || '',
                principal: correo.principal || '',
                tipo: correo.tipo || '',
                tipo_cliente: correo.tipo_cliente || '',
                valor: correo.valor || ''
              }
            }),
          };

          // console.log('result profile obj');
          // console.log(profileObj);

          this.authService.setProfileDetail(profileObj);

          // direcciones que requieren carga
          this.catalogHelper.cargarCantonesInicial(
            profileObj.address.provincia,
            profileObj.address.id_canton
          );

          this.authService.pokeProfileDetail(profileObj);
        })
        .catch((error) => {
        });
    }
  }

  // GET VEHICLES TO UPDATE ONE RECEIVED
  async getUserVehicles() {
    this.vehiclesService
      .getVehiculosCliente(
        this.authService.getSociedadUsuario(),
        this.authService.getCodigoUsuario()
      )
      .then(async (result) => {
        // console.log('carros');
        // console.log(result);

        const d = new Date();
        const dia = d.getDate();
        localStorage.removeItem('fechaDeCarga');
        localStorage.setItem('fechaDeCarga', dia.toString());

        const storedVehicles = this.promisesService.getUserVehicles();
        const storedBaseVehicles = this.promisesService.getUserVehiclesBase();

        if (result && result.length > 0) {
          this.apptHistoryHelper.loadCarHistory(result[0]);
        }

        if (
          !storedBaseVehicles ||
          (JSON.stringify(storedBaseVehicles) != JSON.stringify(result) &&
            Array.isArray(result))
        ) {
          this.promisesService.setUserVehiclesBase(result);
          this.promisesService.setUserVehicles(result);
          this.promisesService.pokeUserVehicles(result);
        }

        result.map((item, index) => {
          item.indexSlide = index; // Set index slide as property
        });
        // GONNA LOAD INSURANCE COMPANIES
        this.cargarAseguradoras(result);
      })
      .catch((error) => {
        // console.warn(error);
        if (!environment.production) {
          // this.utilService.presentAlert({message: error});
        }
        // this.utilService.presentToast('Sin vehiculos asociados');
      });
  }

  cargarAseguradoras(vehicles) {
    this.servicioCatalogo
      .cargarAseguradoras(this.authService.getSociedadUsuario())
      .then(async (result: any) => {
        localStorage.setItem('aseguradoras', JSON.stringify(result));

        this.validateVehicleInsurance(vehicles).then((vehiclesList) => {
          // console.log('insurance assign finish');
          // console.log(vehiclesList);
        }).catch((err) => {
          console.log(err);
        });
      }).catch(error => {
      console.log(error);
      this.utilService.presentToast('Problemas de conexión aseguradora');
    });
  }

  async validateVehicleInsurance(vehicles) {
    return new Promise((resolver, rechazar) => {
      const vehiclesList = [];
      this.promisesService.setLoadingInsuranceCompanies(true);
      vehicles.map(async (vehiculo, index) => {
        // console.log('over loop');
        vehiculo.indexSlide = index; // Set index slide as property
        const insuranceObj = await this.validarAseguradora(vehiculo);
        vehiclesList.push(insuranceObj);
        // console.log('wait loop');

        if (index == vehicles.length - 1) {
          console.log('all insurances companies loaded');
          this.promisesService.setLoadingInsuranceCompanies(false);
          this.promisesService.pokeInsuranceCompaniesVehicles();
          // Set local storage once insurance loaded
          resolver(vehiclesList);
        }
      });

      if (vehiclesList.length <= 0) {
        // Set insurance companies on loaded vehicles
        // this.promisesService.setUserVehicles(vehiclesList);
        // this.promisesService.pokeUserVehicles(vehiclesList);
        // this.promisesService.pokeInsuranceCompanies();
      }
    });
  }

  async validarAseguradora(vehiculo) {
    const aseguradoras = JSON.parse(localStorage.getItem('aseguradoras'));

    this.vehiclesService.getAseguradora(this.authService.getCodigoUsuario(), vehiculo.VIN, '')
      .then(result => {
        result.aseguradora.forEach(aseg => {
          if (aseg.placa == vehiculo.NUM_PLACA) {
            aseguradoras.forEach(value => {
              if (value.nombre == aseg.aseguradora) {
                window.localStorage.setItem(`insurance:${vehiculo.NUM_PLACA}`, JSON.stringify({
                  aseguradora: value.id_lealtad_aseguradora,
                  poliza: aseg.poliza,
                  objAseguradora: value
                }));
              }
            });
          }
        });
      }).catch(error => {
      window.localStorage.setItem(`insurance:${vehiculo.NUM_PLACA}`, '');
    });

    return vehiculo;
  }

  fetchVehicles() {
    this.catalogHelper.cargarImagenesVehiculos();
  }
}
