<div class="client-card-content">
  <ion-grid class="ion-padding">
    <ion-card>
      <ion-card-content>
        <ion-row>
          <ion-col size="12" class="ion-text-center">
            <ion-icon class="success" name="checkmark-circle" color="success"></ion-icon>
          </ion-col>
          <ion-col size="12" class="ion-text-center">
            <ion-text class="txt-confirm" color="gptitle">La cita se generó correctamente con el número <ion-text color="danger" style="font-size: 20px;" >{{code}}</ion-text></ion-text>
          </ion-col>
          <ion-col *ngIf="!authService.bs.isDesktop()" size="12" class="ion-text-center" (click)="scheduleEvent()">
            <div>
              <ion-icon name="calendar"></ion-icon>
            </div>
            <div>
              <ion-text>Agregar a calendario</ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-button (click)="dismissModal()" class="ion-margin-top btn-radius" expand="block" color="gpblue">Aceptar</ion-button>
      </ion-card-content>
    </ion-card>
  </ion-grid>
</div>
