import {Injectable} from '@angular/core';
import {Device} from '@ionic-native/device/ngx';
import {AuthService} from './auth.service';
import {VehicleHelper} from '../helpers/VehicleHelper';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    public device: Device,
    protected auth: AuthService,
    protected vehicleHelper: VehicleHelper
  ) {
  }

  getDeviceInfo(){

    return {
      manufacturer: this.device.manufacturer,
      platform: this.device.platform,
      model: this.device.model,
      uuid: this.device.uuid,
      version: this.device.version,
    }
  }

  getUserInfo(){
    return {
      sap_code: this.auth.getCodigoUsuario(),
      datetime: new Date().toLocaleString(),
      user_email: this.auth.getEmailUser(),
      user_fullname: `${this.auth.getProfile().FirstName} ${this.auth.getProfile().LastName}`,
    }
  }

  getVehicleInfo(){
    return {
      vin: this.vehicleHelper.getCurrentVehicle().VIN,
    }
  }

  getLogInfo(data = {}){
    try{
      return {
        ...this.getDeviceInfo(),
        ...this.getUserInfo(),
        ...this.getVehicleInfo(),
        ...data
      }
    }catch (e) {
      console.log(e);
    }
  }
}
