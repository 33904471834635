import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertController, NavController} from '@ionic/angular';
import {QCar} from '../../models/global';
import {PromisesService} from '../../providers/promises.service';
import {AuthService} from '../../providers/auth.service';
import {CatalogHelper} from '../../helpers/CatalogHelper';
import { VehicleHelper } from '../../helpers/VehicleHelper';

@Component({
  selector: 'app-header-car',
  templateUrl: './header-car.component.html',
  styleUrls: ['./header-car.component.scss'],
})
export class HeaderCarComponent implements OnInit {
  carList: QCar[];
  carSelected: QCar;
  @Output() onChangeCar = new EventEmitter<any>();
  @Output() onBackClick = new EventEmitter<any>();
  @Input() public allowCarSelection = true;
  @Input() backFn = undefined;

  vehiclesImages: any;

  constructor(
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private promisesService: PromisesService,
    private catalogHelper: CatalogHelper,
    public authService: AuthService,
    private vehicleHelper: VehicleHelper,
  ) {
    this.vehiclesImages = this.catalogHelper.getVehiclesImages();

    this.carList = this.promisesService.getUserVehicles();
    this.carSelected = this.vehicleHelper.getCurrentVehicle();
    // console.log('inicializando header car component');
    // console.log(this.carList);
    // console.log(this.carSelected);
  }

  ngOnInit() {
  }

  goBack() {
    if (this.backFn) {
      return this.backFn();
    }
    this.navCtrl.back();
  }

  async changeCar() {

    let inputs = [];

    this.carList.map((x, i) => {
      inputs.push({
        name: `car${i}`,
        type: `radio`,
        label: `${x.DESC_MODELO.toLowerCase()}`,
        value: `${x.VHCLE}`,
        checked: x.VHCLE == this.carSelected.VHCLE,
      });
    });

    const alert = await this.alertCtrl.create({
      cssClass: 'alert-text-capitalize',
      header: 'Seleccione vehículo',
      inputs,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: 'Aceptar',
          handler: (val) => {
            // console.log(val);
            // console.log('Confirm Ok');
            this.carSelected = this.carList.find(x => x.VHCLE == val);
            this.vehicleHelper.setCurrentVehicle(this.carSelected);
            this.vehicleHelper.pokeCurrentVehicle(this.carSelected);
            this.onChangeCar.emit(this.carSelected);
          }
        }
      ]
    });

    await alert.present();
  }

}
