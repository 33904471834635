import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-multiline',
  templateUrl: './multiline.component.html',
  styleUrls: ['./multiline.component.scss'],
})
export class MultilineComponent implements OnInit {

  @Input() lineLimit: number;
  @Input() className: string;

  constructor() { }

  ngOnInit() {}

}
