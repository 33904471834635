import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { onError } from '@apollo/client/link/error';
import { DocumentNode } from 'graphql';
import { HIGHLIGHTS_QUERY } from './queries/benefits.query';

@Injectable({
  providedIn: 'root'
})

export class BenefitsGraphqlService {

  DEFAULT_QUERY = gql`query getBenefits($country: String!){

    categoriesByCountry(country: $country) {

      id, name, description,
      backgroundColor, webIcon, parent{
        id
        name
      }

      business {
        id,
        logoUrl,
        business { id }, category{ name },
        benefits {
          id, benefit, header, description,
          benefitArtUrl, canShare,
          offices {
            id
            office
            latitude
            longitude
          }
          business {
            id,
            facebookUrl, 
            instagramUrl, 
            twitterUrl, 
            whatsappUrl,
            business,
            logoUrl,
            geolocation
          },
          tags {
            name
          }
        }
      }
    }
  }`;

  constructor(private apollo: Apollo) {
  }

  getBenefits(countryArg: string, query: DocumentNode) {
    return this.apollo
      .watchQuery({
        query, variables: { country: countryArg }
      });
  }

  getHighlights(countryArg: string) {
    return this.apollo
      .watchQuery({
        query: HIGHLIGHTS_QUERY, variables: { country: countryArg }
      });
  }

  // Obtenemos los beneficios que tenemos en local
  getBenefitLocal() {
    let benefit = !!localStorage.getItem("benefitsLocal");
    return benefit;
  }

  // Almacenamos el beneficios seleccionado en cache
  saveBenefitLocal(benefit: any, otherBenefits?: any, text: string = "Beneficios") {
    const existeLocal = this.getBenefitLocal();
    if (!existeLocal) {
      localStorage.setItem("benefitsLocal", JSON.stringify(""));
    }
    let benefits = JSON.parse(localStorage.getItem("benefitsLocal"));
    benefits = [];

    let data = {
      text,
      ...benefit,
      otherBenefits: [
        ...otherBenefits
      ]
    }
    benefits.push(data);
    localStorage.setItem('benefitsLocal', JSON.stringify(benefits));
  }
  
}
