import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, PopoverController, ToastController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {PromisesService} from '../../providers/promises.service';
import {HomeService} from '../../providers/home.service';
import {VehiculosService} from '../../providers/vehiculos.service';
import {UtilService} from '../../providers/util.service';
import {MainCarSelectorComponent} from '../main-car-selector/main-car-selector.component';
import {CarEditOptionsComponent} from '../car-edit-options/car-edit-options.component';
import {Subscription} from 'rxjs';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {VehicleHelper} from '../../helpers/VehicleHelper';
import {AnalyticsService} from "../../providers/analytics.service";

@Component({
  selector: 'app-emergency-popover',
  templateUrl: './emergency-popover.component.html',
  styleUrls: ['./emergency-popover.component.scss'],
})
export class EmergencyPopoverComponent implements OnInit {
  usuario: any;
  vehiculos = [];
  vehicleInsurance: any;
  aseguradoras: any = [];
  selectedVehiculos: any;
  tipo: any;

  insurancesCompaniesLoaded: Subscription;

  @ViewChild(MainCarSelectorComponent, {static: true}) CarSelector: MainCarSelectorComponent;

  constructor(
    private toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    private router: Router,
    private servicioAuth: AuthService,
    private promiseService: PromisesService,
    private utilService: UtilService,
    private homeService: HomeService,
    private vehiculosService: VehiculosService,
    private vehiclesHelper: VehicleHelper,
    public alertCtrl: AlertController,
    public firebaseX: FirebaseX,
    public analyticsService: AnalyticsService,
    private popoverCtrl: PopoverController
  ) {
    this.usuario = this.servicioAuth.getProfile();
    this.selectedVehiculos = this.vehiclesHelper.getCurrentVehicle();
    this.aseguradoras = JSON.parse(localStorage.getItem('aseguradoras'));
    this.vehicleInsurance = this.promiseService.getInsuranceVehiclesCompanies(this.selectedVehiculos.NUM_PLACA);
    // console.log('Aseguradora');
    // console.log(this.vehicleInsurance);
    this.vehiculos = this.promiseService.getUserVehicles();

    if (this.promiseService.getLoadingInsuranceCompanies()) {
      this.utilService.presentLoading();
    }
  }

  ionViewDidEnter() {
    this.analyticsService.logEvent(AnalyticsService.OPEN_EMERGENCY, 'emergency');
  }

  ngOnInit() {
    this.insurancesCompaniesLoaded = this.promiseService.getInsuranceCompaniesObservable().subscribe(() => {
      // console.log('insurances companies loaded');
      this.utilService.dismissLoading();
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }


  llamar() {
    // console.log('datos');
    // console.log(this.selectedVehiculos);
    // console.log(this.tipo);
    if (this.selectedVehiculos == undefined || this.selectedVehiculos == '' || this.tipo == undefined || this.tipo == '') {
      this.utilService.presentToast('Tipo de emergencia requerido');
    } else {
      this.procesoLlamar();
    }
  }

  procesoLlamar() {
    // console.log('proceso de emergencia');
    // console.log(this.selectedVehiculos);

    if (this.vehicleInsurance == undefined || this.vehicleInsurance == '' || this.vehicleInsurance == '0') {
      //console.log('Falta asignar aseguradora');
      this.showPrompt();
    } else {
      this.tieneAseguradora();
    }
  }

  async showPrompt() {
    // console.log('no tiene aseguradora');
    const prompt = await this.alertCtrl.create({
      header: 'Alerta',
      message: 'El vehículo no posee aseguradora, ¿Desea configurar aseguradora?',
      cssClass: 'buttonCss',
      buttons: [
        {
          text: 'Cancelar',
        },
        {
          text: 'Aceptar',
          handler: async (data) => {
            this.popoverCtrl.dismiss({data: true});
          }
        }
      ]
    });
    await prompt.present();
  }

  async tieneAseguradora() {
    // console.log('tiene aseguradora');
    // console.log(this.selectedVehiculos);

    if (this.tipo == 'choque') {
      this.realizarLLamada(this.vehicleInsurance.objAseguradora.telefono_colision);
    } else {
      this.realizarLLamada(this.vehicleInsurance.objAseguradora.telefono_asistencia);
    }
  }

  async realizarLLamada(telefono) {
    window.open('tel:' + telefono);
  }
}
