import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment as env} from '../../../../environments/environment';
import {AlertController, LoadingController, ModalController, NavController, NavParams, Platform, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {AyudaService} from '../../../providers/ayuda.service';
import {AuthService} from '../../../providers/auth.service';
import {Subscription} from 'rxjs';
import {UtilService} from '../../../providers/util.service';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {AnalyticsService} from "../../../providers/analytics.service";

@Component({
  selector: 'app-membership-modal',
  templateUrl: './membership-modal.component.html',
  styleUrls: ['./membership-modal.component.scss'],
})
export class MembershipModalComponent implements OnInit, OnDestroy {
  usuario: any;
  catalogo: any;
  mostrarMembresia: any = false;
  imgMembresia: any;
  fallaLoading: any;
  backButton: Subscription;

  constructor(
    public authService: AuthService,
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private router: Router,
    public loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private ayudaService: AyudaService,
    private platform: Platform,
    private firebaseX: FirebaseX,
    private analyticsService: AnalyticsService,
    private utilService: UtilService,
    private navCtrl: NavController
    // private device: Device,
    // private screenOrientation: ScreenOrientation
  ) {
    // this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    this.usuario = this.authService.getProfile();
    this.catalogo = JSON.parse(localStorage.getItem('catalogo'));
    this.validarTipoMembresia();
  }

  ionViewDidEnter() {
    this.analyticsService.logEvent(AnalyticsService.OPEN_MEMBERSHIP, 'membership');
  }

  ngOnInit() {
    this.initBackButton();
  }

  ngOnDestroy() {
    this.backButton.unsubscribe();
  }

  initBackButton() {
    this.backButton = this.platform.backButton.subscribeWithPriority(999999, async () => {
      return this.navCtrl.back({animated: true});
    });
  }

  async validarTipoMembresia() {
    var segmento = this.usuario.segmento.toUpperCase();
    if (segmento.includes(this.catalogo.segmentos[0].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_platinum-bc.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[1].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_gold-bc.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[2].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_plata-bc.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[3].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_card_membresia.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[4].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_card_membresia.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[5].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_gold-bc.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[6].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_platinum-bc.jpg';
      this.mostrarMembresia = true;
    } else if (segmento.includes(this.catalogo.segmentos[7].segmento.toUpperCase())) {
      this.imgMembresia = './assets/imgs/membresias/ic_card_membresia.jpg';
      this.mostrarMembresia = true;
    } else {
      this.mostrarMembresia = false;
      const confirm = await this.alertCtrl.create({
        header: 'Alerta',
        message: 'No posee membresías, ¿desea enviar un correo para que se le asigne una membresía?',
        cssClass: 'gq-alert-dosBotones',
        buttons: [
          {
            text: 'Cancelar',
            handler: () => {
              this.closeModal();
            }
          },
          {
            text: 'Enviar',
            handler: () => {
              this.enviarReporte();
            }
          }
        ]
      });
      await confirm.present();
      //this.presentToast('Usuario sin membresia asignada');
    }
  }

  enviarReporte() {
    // var deviceCompleto = this.device.manufacturer + ', ' + this.device.platform + ', ' + this.device.model;
    var descripcionAsunto = 'Problemas de membresia';
    // if (this.device.manufacturer == null && this.device.platform == null) {
    var deviceCompleto = 'Web/Pruebas';
    // }
    var data = {
      tipo_mensaje: 'falla',
      id_cliente: this.usuario.codigo,
      sociedad: this.usuario.sociedad,
      asunto: descripcionAsunto,
      mensaje: 'El cliente no posee membresia asignada y solicita ayuda para que se le asigne',
      version: env.APP_VERSION,
      version_code: env.APP_VERSION,
      device: deviceCompleto,
      pais: this.usuario.pais,
      segmento: this.usuario.segmento,
    };
    this.utilService.presentLoading();
    this.ayudaService.enviarFalla(data)
      .then(async (result) => {
        this.utilService.dismissLoading();
        const confirm = await this.alertCtrl.create({
          header: 'Mensaje:',
          message: result,
          cssClass: 'buttonCss',
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.closeModal();
              }
            }
          ]
        });
        await confirm.present();
      }).catch(error => {
      this.utilService.dismissLoading();
      // console.warn(error);
      this.presentToast('Problemas de conexión: MBSHIP');
    });
  }

  async presentToast(mensaje) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 3000,
      position: 'bottom'
    });

    toast.onDidDismiss().then(() => {
      this.closeModal();
    });

    toast.present();
  }

  public closeModal() {
    this.navCtrl.back();

  }


}
