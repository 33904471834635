import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {AlertController, Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PositionService {

  constructor(private diagnostic: Diagnostic,
              private platform: Platform,
              private alertCtrl: AlertController,
              private geolocation: Geolocation,
              private locationAccuracy: LocationAccuracy) { }

  async checkGPSPermission() {
    const isLocationAuth = await this.diagnostic.isLocationAuthorized();

    // If is authorized to use gps, then check for turn on
    if (isLocationAuth) {
      return await this.activeLocation();
    } else {
      return await this.requestGPSPermission();
    }
  }

  async activeLocation() {
    const canRequest = await this.locationAccuracy.canRequest();
    const isAvailable = await this.diagnostic.isLocationAvailable();

    if (isAvailable) {
      return await this.getCurrentPosition();
    }

    return await this.requestGPSPermission();
  }

  requestGPSPermission() {
    return new Promise((resolve, reject) => {
      this.locationAccuracy.canRequest().then(async (canRequest: boolean) => {
        if (!canRequest) {
          await this.checkGPSStatus();
          this.diagnostic.requestLocationAuthorization()
            .then(
              () => {
                resolve(this.activeLocation());
              },
              (error) => {
                reject(error);
              }
            );
        } else {
          this.locationAccuracy
            .request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
            .then(
              () => {
                resolve(this.getCurrentPosition());
              },
              (error) => {
                reject(error);
              }
            );
        }
      });
    });
  }


  getCurrentPosition() {
    return new Promise((resolve, reject) => {
      this.geolocation
        .getCurrentPosition()
        .then(
          (location) => {
            console.log('location');
            console.log(location);
            resolve(location);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async checkGPSStatus() {
    this.diagnostic.getLocationAuthorizationStatus().then(async (status) => {
      console.log(status);
      switch (status) {
        case this.diagnostic.permissionStatus.NOT_REQUESTED:
          console.log('Permission not requested');
          this.diagnostic.requestLocationAuthorization().then(this.checkGPSStatus, this.onError);
          break;
        case this.diagnostic.permissionStatus.DENIED_ONCE:
          break;
        case this.diagnostic.permissionStatus.DENIED_ALWAYS:
          console.log('Permission denied');

          if (this.platform.is('ios')) {
            const alert = await this.alertCtrl.create({
              header: 'La aplicación no tiene permisos para acceder a su ubicación, ¿le gustaría activarlo desde la sección ' +
                'de configuración de su teléfono?',
              buttons: [{
                text: 'Sí',
                handler: () => {
                  this.diagnostic.switchToSettings().then();
                }
              }, {
                text: 'No',
                handler: () => {
                  // LOVE SPACES :)
                }
              }]
            });
            // now present the alert on top of all other content
            await alert.present();
          }
          break;
        case this.diagnostic.permissionStatus.GRANTED:
          break;
        case this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
          console.log('Permission granted always');
          return true;
          // @ts-ignore
          break;
      }
    }, this.onError);
  }

  onError(error) {
    console.error('The following error occurred: ' + error);
  }

}
