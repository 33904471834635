import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AlertController, Platform } from "@ionic/angular";
import { BenefitService } from "../firebase/benefit.service";

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  info: any;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private appVersion: AppVersion,
    private ian: InAppBrowser,
    private plt: Platform,
    private firebaseService: BenefitService
  ) {}

  /* 
    L = LIBRE
    M = MANTENIMIENTO
    
    {
      "AD" : {
      "enabled" : true,
      "minorMsg" : {
        "btn" : "Descargar",
        "msg" : "Actualizacion Disponible!",
        "title" : "Actualizacion Disponible"
      }
  },
    "M" : {
      "enabled" : false,
      "msg" : {
        "btn" : "Aceptar",
        "msg" : "Estamos mejorando para darte una mejor experiencia",
        "title" : "App en mantenimiento"
      }
  },
      "current_version" : "6.0.4",
      "tipo" : "L"
  }


  */

  async checkForUpdate() {
    this.info = this.firebaseService
      .getVersionApp()
      .valueChanges()
      .subscribe(async (info) => {
        //console.log("info:::", info);
        let tipo = info.tipo;

        if (info.tipo === "M") {
          if (!info[tipo].enabled) {
            this.presentAlert(info[tipo].msg.title, info[tipo].msg.msg);
          }
        } else {
          const versionNumber = await this.appVersion.getVersionNumber();
          const splitVersion = versionNumber.split(".");
          const serverVersion = info.current_version.split(".");

          if (
            serverVersion[0] > splitVersion[0] ||
            serverVersion[1] > splitVersion[1]
          ) {
            this.presentAlert(
              info["AD"].minorMsg.title,
              info["AD"].minorMsg.msg,
              info["AD"].minorMsg.btn
            );
          } else {
          }
        }
      });
  }

  openAppstoreEntry() {
    if (this.plt.is("android")) {
      window.open("market://details?id=com.migrupoq.app", "_system");
    } else {
      this.ian.create("itms-apps://itunes.apple.com/app/id972874159", "_blank");
    }
  }

  async presentAlert(header, message, buttonText = "", allowClose = false) {
    const buttons: any = [];

    if (buttonText != "") {
      buttons.push({
        text: buttonText,
        handler: () => {
          this.openAppstoreEntry();
        },
      });
    }

    if (allowClose) {
      buttons.push({
        text: "Cerrar",
        role: "cancel",
      });
    }

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
      backdropDismiss: allowClose,
    });

    await alert.present();
  }
}
