import {Injectable} from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {catchError} from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private httpNative: HTTP,
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform) {
    // this.httpNative.setRequestTimeout(2500);
  }


  // HEADERS
  async getNativeHeader(nToken) {
    let headers: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache'
    };

    if (nToken) {
      headers['access-token'] = window.localStorage.getItem(env.smartq.storagekeys.smartToken) || '';
    }
    return headers;
  }

  async getBrowserHeader(nToken, any: boolean = false) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Cache-control', 'no-cache')
      .append('Expires', '0')
      .append('Pragma', 'no-cache');
    if (nToken) {
      headers = headers
        .append('access-token', window.localStorage.getItem(env.smartq.storagekeys.smartToken) || '');
      // console.log(headers);
    }
    return headers;
  }


  // POST
  async post<T>(url: string[], body, nToken = false): Promise<T> {
    let _url = url.join('/');

    return this.isDesktop() ? this.browserPost<T>(_url, body, await this.getBrowserHeader(nToken))
      :
      this.nativePost<T>(_url, body, await this.getNativeHeader(nToken));
  }

  private async nativePost<T>(url, body, headers): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        let x = await this.httpNative.post(url, body, headers);
        resolve(JSON.parse(x.data));
      } catch (ex) {
        try{
          reject({error: JSON.parse(ex.error).message, status: ex.status});
        }catch (e) {
          reject({error: 'Algo salio mal', status: false})
        }
      }
    });
  }


  private async browserPost<T>(url, body, headers): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post<T>(url, this.formData(body), {headers}).subscribe(d => resolve(d), err => {
        reject({
        status: err.status,
        message: err.error.message
      })});
    });
  }


  // GET

  async get<T>(url: string[], nToken = false, any = false): Promise<T> {
    let _url = url.join('/');
    return this.isDesktop() ? this.browserGet<T>(_url, await this.getBrowserHeader(nToken), any)
      :
      this.nativeGet<T>(_url, await this.getNativeHeader(nToken), any);
  }


  private async nativeGet<T>(url, headers, any): Promise<T> {
    // console.log('START GET');
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        // this.httpNative.setRequestTimeout(5.0);
        // console.log('STARTING GET');
        let x = await this.httpNative.get(url, null, headers);
        if (any) {
          return resolve(x.data);
        }
        resolve(JSON.parse(x.data));

        // console.log('FINISH GET GOOD');
        // alert('GOOD ' + JSON.stringify(x.data));
      } catch (ex) {
        // alert(JSON.stringify(ex.status));
        try {

          reject({error: JSON.parse(ex.error).message, status: ex.status});
        } catch (ex1) {
          // console.log('ERROR HERE', ex);
          reject({error: ex});
        }
        reject({error: JSON.parse(ex.error).message, status: ex.status});
        // console.log('FINISH GET BAD');
      }
    });
  }

  public async browserGet<T>(url, headers, any = false): Promise<T> {
    let responseType = any ? 'text' as 'json' : 'json';

    return new Promise((resolve, reject) => {
      this.http.get<T>(url, {headers, responseType}).subscribe(d => resolve(d), err => {
        try {
          reject({status: err.status, message: err.error.message});
        } catch (ex) {
          reject({status: 500, message: err});
        }
      });
    });
  }


  // PUT

  async put<T>(url: string[], body, nToken = false): Promise<T> {
    // let final ?
    // console.log(body);
    let _url = url.join('/');
    // console.log(_url);
    // console.log(_url);

    return this.isDesktop() ? this.browserPut<T>(_url, body, await this.getBrowserHeader(nToken))
      :
      this.nativePut<T>(_url, body, await this.getNativeHeader(nToken));
  }

  private async nativePut<T>(url, body, headers): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        // this.httpNative.setRequestTimeout(5.0);
        let x = await this.httpNative.put(url, body, headers);
        resolve(JSON.parse(x.data));
        // alert('GOOD ' + JSON.stringify(x.data));
      } catch (ex) {
        // alert(JSON.stringify(ex.status));
        reject({error: JSON.parse(ex.error).message, status: ex.status});
      }
    });
  }


  private async browserPut<T>(url, body, headers): Promise<T> {
    // console.log(headers);
    return new Promise((resolve, reject) => {
      this.http.put<T>(url, this.formData(body), {headers}).subscribe(d => resolve(d), err => reject({
        status: err.status,
        message: err.error.message
      }));
    });
  }


  // DELETE

  async delete<T>(url: string[], nToken = false): Promise<T> {
    let _url = url.join('/');
    return this.isDesktop() ? this.browserDelete<T>(_url, await this.getBrowserHeader(nToken))
      :
      this.nativeDelete<T>(_url, await this.getNativeHeader(nToken));
  }

  private async nativeDelete<T>(url, headers): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        // this.httpNative.setRequestTimeout(5.0);
        let x = await this.httpNative.delete(url, null, headers);
        resolve(JSON.parse(x.data));
        // alert('GOOD ' + JSON.stringify(x.data));
      } catch (ex) {
        // alert(JSON.stringify(ex.status));
        reject({error: JSON.parse(ex.error).message, status: ex.status});
      }
    });
  }

  private async browserDelete<T>(url, headers): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.delete<T>(url, {headers}).subscribe(d => resolve(d), err => reject({status: err.status, message: err.error.message}));
    });
  }

  postLoginHttp(userData): Observable<any> {
    return this.http.post<any>(env.cronos.login, userData);
  }

  postAddFavorite(id, token) {

    const formData = new FormData();
    formData.append('access_token', token);

    return this.http.post<any>(env.cronos.addFavorite + id + '/', formData );
  }

  postRemoveFavorite(id, token) {

    const formData = new FormData();
    formData.append('access_token', token);

    return this.http.post<any>(env.cronos.removeFavorite + id + '/', formData);
  }

  public isDesktop() {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  formData(body) {
    return Object.keys(body).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');
  }
}
