import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavController, Platform, PopoverController} from '@ionic/angular';
import {SmartHelper} from '../../helpers/smartq/SmartHelper';
import {AuthService} from '../../providers/auth.service';
import {UtilService} from '../../providers/util.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-activate-alert',
  templateUrl: './activate-alert.component.html',
  styleUrls: ['./activate-alert.component.scss'],
})
export class ActivateAlertComponent implements OnInit, OnDestroy {
  backBtnSubscriber: Subscription;

  vehicle: any;
  updated = false;
  alerts;
  selectedAlert;

  constructor(
    private popoverCtrl: PopoverController,
    private smartHelper: SmartHelper,
    public platform: Platform,
    private authService: AuthService,
    private utilHelper: UtilService,
    private navCtrl: NavController) {
  }

  ngOnInit() {
    if (this.alerts.length) {
      this.selectedAlert = this.alerts[0];
    }
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      return this.dismissModal();
    });
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  activateAlert() {
    this.utilHelper.presentLoading();

    this.smartHelper.activateAlert(this.vehicle, this.selectedAlert).then((data: any) => {
      this.utilHelper.dismissLoading();
      // console.log(data);
      if (!data.hasOwnProperty('error')) {
        this.utilHelper.presentToast(data.data[0].success);
        this.updated = true;
        this.dismissModal();
      } else {
        this.utilHelper.presentAlert({message: data.error});
      }
    }).catch((err) => {
      // console.log(err);
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss({updated: this.updated});
  }

}
