<div class="ion-text-center ion-padding pop-header">
  <ion-text>EDITAR INFORMACIÓN</ion-text>
</div>
<ion-list>
  <ion-item>
    <ion-label position="stacked">Limite de velocidad:</ion-label>
    <ion-input type="number" inputmode="numeric"
               [(ngModel)]="speedLimit.velocidad"
               class="ion-text-left"></ion-input>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col class="ion-no-padding">
    <ion-button class="ion-no-margin" (click)="saveSpeedLimit()" expand="full" fill="clear">Guardar</ion-button>
  </ion-col>
  <ion-col class="ion-no-padding">
    <ion-button (click)="dismissModal()" class="ion-no-margin" expand="full" fill="clear">Cancelar</ion-button>
  </ion-col>
</ion-row>
