<ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col (click)="goBack()" size="2" class="ion-text-center ion-no-padding">
      <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
    </ion-col>
    <ion-col size="10" class="ion-no-margin ion-no-padding">
      <div class="main-title ion-align-items-center">
        <div class="ion-align-items-center d-flex">
          <ion-col size="8">
            <div>
              <ion-text style="font-size: 16px;" class="ion-text-capitalize">{{carSelected?.DESC_MODELO.toLowerCase()}}</ion-text>
            </div>
            <!--<div *ngIf="allowCarSelection">-->
              <!--<small (click)="changeCar()" class="change-car">Cambiar</small>-->
            <!--</div>-->
          </ion-col>
          <ion-col size="4">
            <ion-row class="ion-text-center ion-align-items-center">
              <div class="d-flex m-auto ion-text-center ion-align-items-center">
                <img width="90px"  [default]="'https://gq.cronos.mobi/assets/vehiculos/' + carSelected?.MARCA.replace(' ', '_') + '/logo.webp'"
                     [src]="'https://gq.cronos.mobi/assets/vehiculos/' + carSelected?.MARCA.replace(' ', '_') + '/'  + vehiclesImages[carSelected.MATNR]?.image"
                     alt="">
              </div>
            </ion-row>
          </ion-col>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
