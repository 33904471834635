import {Injectable} from '@angular/core';
import {BaseService} from '../../services/http/base.service';
import {environment as env} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private bs: BaseService) {
  }

  getCollecters(country) {
    return this.bs.get([`${env.crediq.endpoints.collecters}?pais=${country}`]);
  }

}
