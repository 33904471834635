import { Injectable } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/database";
import { Benefit } from "../../models/benefit";

@Injectable({
  providedIn: "root",
})
export class BenefitService {
  benefitListRef: AngularFireList<Benefit>;
  vehiclesListRef: AngularFireObject<any>;
  benefitRef: AngularFireObject<Benefit>;
  versionAppRef: AngularFireObject<any>;

  constructor(private db: AngularFireDatabase) {}

  createBenefit(benefit: Benefit) {
    return this.benefitListRef.push({
      name: benefit.name,
      benefit: benefit.benefit,
      benefitTitle: benefit.benefitTitle,
      description: benefit.description,
      thumb: benefit.thumb,
      bigThumb: benefit.bigThumb,
      country: benefit.country,
      category: benefit.category,
    });
  }

  getBenefit(id) {
    this.benefitRef = this.db.object("/benefit/" + id);
    return this.benefitRef;
  }

  getBenefitList() {
    this.benefitListRef = this.db.list("/benefit");
    return this.benefitListRef;
  }

  getVehiclesImages() {
    this.vehiclesListRef = this.db.object("vehicles");
    return this.vehiclesListRef;
  }

  getVersionApp() {
    this.versionAppRef = this.db.object("version");
    return this.versionAppRef;
  }
}
