import StorageHelper from '..//StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../../providers/auth.service';
import {UtilService} from '../../providers/util.service';
import {LoanService} from '../../providers/crediq/loan.service';
import {environment as env} from '../../../environments/environment';
import {PromisesService} from '../../providers/promises.service';
import {defaultCarProps, LoanLight, QCar} from '../../models/global';

@Injectable({
  providedIn: 'root'
})

export class LoanHelper extends StorageHelper {
  private loadingLoanDetails = false;
  private loadingLoanDetailsBG = false;
  private loadingLoanDetailsLight = false;
  private loadingUserCards = false;
  private storageKeys = env.crediq.storagekeys;

  loanDetails: any;
  loanDetailsLight: any;
  userCards: any;

  $openPaymentCard = new Subject<any>();
  $userCards = new Subject<any>();
  $loanDetails = new Subject<any>();
  $loanDetailsLight = new Subject<any>();
  $userCurrentVehicle = new Subject<any>();

  private userCrediQCurrentVehicle: QCar;
  private userCrediQCurrentVehicleIndex: number;

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private promisesService: PromisesService,
    private loanService: LoanService,
  ) {
    super();
  }

  setIsLoadingloanDetails(status: boolean) {
    this.loadingLoanDetails = status;
  }

  setIsLoadingloanDetailsBG(status: boolean) {
    this.loadingLoanDetailsBG = status;
  }

  setIsLoadingloanDetailsLight(status: boolean) {
    this.loadingLoanDetailsLight = status;
  }

  setUserCards(userCards) {
    this.userCards = userCards;
    window.localStorage.setItem(env.production ? `dev:${this.storageKeys.userCardsKey}` : `prod:${this.storageKeys.userCardsKey}`, JSON.stringify(userCards));
  }

  setUserLoans(userloans, persist = true) {
    this.loanDetails = userloans;
    if (persist) {
      window.localStorage.setItem(env.production ? `dev:${this.storageKeys.userLoansDetailKey}` : `prod:${this.storageKeys.userLoansDetailKey}`, JSON.stringify(userloans));
    }
  }

  setUserLoansLight(userloansLight, persist = true) {
    this.loanDetailsLight = userloansLight;
    if (persist) {
      window.localStorage.setItem(env.production ? `dev:${this.storageKeys.userLoansDetailLightKey}` : `prod:${this.storageKeys.userLoansDetailLightKey}`, JSON.stringify(userloansLight));
    }
  }

  getUserCards(): any {
    return this.userCards && this.userCards || JSON.parse(window.localStorage.getItem(env.production ? `dev:${this.storageKeys.userCardsKey}` : `prod:${this.storageKeys.userCardsKey}`)) || [];
  }

  getUserLoans(): any {
    return this.loanDetails && this.loanDetails || JSON.parse(window.localStorage.getItem(env.production ? `dev:${this.storageKeys.userLoansDetailKey}` : `prod:${this.storageKeys.userLoansDetailKey}`)) || null;
  }

  getUserLoansLight(): any {
    return this.loanDetailsLight && this.loanDetailsLight || JSON.parse(window.localStorage.getItem(env.production ? `dev:${this.storageKeys.userLoansDetailLightKey}` : `prod:${this.storageKeys.userLoansDetailLightKey}`)) || null;
  }

  pokeUserCards(data) {
    this.$userCards.next(data && data || this.getUserCards());
  }

  pokeUserLoansDetail(data) {
    this.$loanDetails.next(data && data || this.getUserLoans());
  }

  pokeUserLoansDetailLight(data) {
    this.$loanDetailsLight.next(data && data || this.getUserLoansLight());
  }



  getUserCardsObservable() {
    return this.$userCards.asObservable();
  }

  getUserLoansDetailObservable() {
    return this.$loanDetails.asObservable();
  }

  getOpenPaymentCardAsObservable() {
    return this.$openPaymentCard.asObservable();
  }

  pokePaymentCard(val) {
    return this.$openPaymentCard.next(val);
  }

  getUserLoansDetailLightObservable() {
    return this.$loanDetailsLight.asObservable();
  }

  isLoadingloanDetail() {
    return this.loadingLoanDetails;
  }

  isLoadingloanDetailBG() {
    return this.loadingLoanDetailsBG;
  }

  isLoadingloanDetailLight() {
    return this.loadingLoanDetailsLight;
  }

  isLoadingUserCards() {
    return this.loadingUserCards;
  }

  async requestloanDetailWS(loanID) {
    return this.loanService.requestLoanDetail(this.authService.getPaisCodeUsuario(), this.authService.getUserCrediQCode(), this.authService.getCrediQUserEmail(), loanID);
  }

  loginGqCrediQWS() {
    if (!this.isValidKeyETA(env.production ? 'prod:crediq:user' : 'qa:crediq:user', 1) || this.authService.getUserCrediQ() == null || this.authService.getUserCrediQ()['process-status'] == 'user-not-found') {
      this.authService.setIsLoginCrediQ(true);
      this.loanService.loginCrediQ(this.authService.getPaisCodeUsuario(), 'migrupoq', this.authService.getUsuarioDocNumber(), this.authService.getEmailUser(), this.authService.getPasswdCrypted()).then((result: any) => {
        this.authService.setIsLoginCrediQ(false);
        if (result.hasOwnProperty('codigoCliente')) {
          this.authService.setCrediQUser(result);
          this.authService.hasCrediQProfile = true;
          this.authService.pokeUserCrediQ(result);
        } else {
          this.authService.hasCrediQProfile = false;
          this.authService.setCrediQUser(result);
          this.authService.pokeUserCrediQ({});
        }
      }).catch(error => {
        // console.warn(error);
        this.authService.setIsLoginCrediQ(false);
        this.authService.hasCrediQProfile = false;
        this.authService.pokeUserCrediQ({});

        // 'Problemas de conexión: LOAH'
        this.utilService.presentToast('Falló al iniciar sesión en CrediQ');
      });
    } else {
      const result = this.getItem(env.production ? 'prod:crediq:user' : 'qa:crediq:user');
      this.authService.setCrediQUser(result);
      this.authService.hasCrediQProfile = true;
      this.authService.pokeUserCrediQ({error: { error: 'Falló al iniciar sesión en CrediQ'}});
    }
  }

  getErrorMsg(response) {
    // alert(JSON.stringify(response));
    return response.hasOwnProperty('error') && response.error.hasOwnProperty('error') ? response.error.error : 'Algo salió mal';
  }

  getuserCardsWS(skipCache = false) {
    if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.userCardsKey}` : `prod:${this.storageKeys.userCardsKey}`, 1) || skipCache) {
      this.loanService.getUserCards(this.authService.getPaisCodeUsuario(), this.authService.getUserCrediQ().email, this.authService.getUserCrediQCode()).then((result: any) => {
        // console.log('user cards');
        // console.log(result);
        if (result['process-status']) {
          this.setUserCards(result.tarjetas);
          this.pokeUserCards(result.tarjetas);
        }
      }).catch(error => {
        // console.warn(error);
        // 'Problemas de conexión: LOAH'
        this.utilService.presentToast('Error al obtener las tarjetas asociadas.');
      });
    }
  }

  getloanDetailsWS(skipCache = false) {
    const key = env.production ? `dev:${this.storageKeys.userLoansDetailKey}` : `prod:${this.storageKeys.userLoansDetailKey}`;
    if (!this.isValidKeyETA(key, 1) || this.getItem(key) == null || skipCache || this.checkLastUpdateMinutes()) {
      if (!skipCache) {
        this.setIsLoadingloanDetails(true);
      }

      if (this.checkLastUpdateMinutes()) {
        this.setIsLoadingloanDetailsBG(true);
      }
      this.loanService.getLoanDetails(this.authService.getPaisCodeUsuario(), this.authService.getUserCrediQCode()).then(result => {
        // TODO: add wrapper for window.localStorage (centralize key name, set and get method in StorageHelper class)
        window.localStorage.setItem('appTime', (new Date().getTime()).toString());

        this.setIsLoadingloanDetails(false);
        this.setUserLoans(result);
        this.pokeUserLoansDetail(result);

        this.setIsLoadingloanDetailsBG(false);
      }).catch(error => {
        this.setIsLoadingloanDetails(false);
        // console.warn(error);
        this.setIsLoadingloanDetailsBG(false);
        // 'Problemas de conexión: LOAH'
        this.utilService.presentToast('Error al obtener detalle de los prestamos.');
      });
    }
  }

  getloanDetailsLightWS(skipCache = false) {
    const key = env.production ? `dev:${this.storageKeys.userLoansDetailLightKey}` : `prod:${this.storageKeys.userLoansDetailLightKey}`;
    // debugger
    if (!this.isValidKeyETA(key, 1) || this.getItem(key) || !this.getItem(key).length || skipCache) {
      if (!skipCache) {
        this.setIsLoadingloanDetailsLight(true);
      }
      this.loanService.getLoanDetailsLight(this.authService.getPaisCodeUsuario(), this.authService.getUserCrediQCode()).then(result => {
        this.setIsLoadingloanDetailsLight(false);

        this.setUserLoansLight(result);
        this.pokeUserLoansDetailLight(result);
      }).catch(error => {
        this.setIsLoadingloanDetailsLight(false);
        // console.warn(error);
        // 'Problemas de conexión: LOAH'
        this.utilService.presentToast('Error al obtener la información del usuario.');
      });
    }
  }

  getMappedVehicles() {
    let crediQVehicles: QCar[] = [];
    if (this.getUserLoans()) {
      this.getUserLoans().detalleUS.map((loan) => {
        crediQVehicles.push({
            ...defaultCarProps,
            ...{
              MODELO: loan.modelo,
              NUM_PLACA: loan.numeroPlaca,
            }
          }
        );
      });
    }
    return crediQVehicles;
  }

  getMappedVehiclesLight() {
    let crediQVehicles: QCar[] = [];
    if (this.getUserLoansLight() && !this.getUserLoansLight().length && this.getUserLoansLight().detalleUS) {
      this.getUserLoansLight().detalleUS.map((loan: LoanLight) => {
        crediQVehicles.push({
            ...defaultCarProps,
            ...{
              MODELO: loan.modelo,
              NUM_PLACA: loan.numeroPlaca,
              VIN: loan.numeroChasis,
              ANIO_PLACA: loan.anio_vehiculo,
              SOCIEDAD: loan.sociedadSAP,
              MARCA: loan.marca,
              //grupoq: 'no'
            }
          }
        );
      });
    }
    return crediQVehicles;
  }

  /**
   * Loop over loan list to get each NPE
   * @param loanList
   */
  getLoanNPEFromList(loanList = null) {
    loanList = loanList || this.getUserLoansLight();

    // debugger;
    loanList.detalleUS.map((loan) => {
      if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.loanNPE}:${loan.numeroPrestam}` : `prod:${this.storageKeys.loanNPE}:${loan.numeroPrestam}`, 180)) {
        this.loanService.getLoanNPE(this.authService.getPaisCodeUsuario(), this.authService.getUserCrediQCode(), loan.numeroPrestam).then((result: any) => {
          // this.loanService.getLoanNPE('SV', 'SV00004830', loan.numeroPrestam).then((result: any) => {
          // console.log('loan npe');
          // console.log(result);
          // this.setIsLoadingloanDetails(false);
          if (result && result.prestamos && result.prestamos.length) {
            let data = result.prestamos[0];
            data.referenciaTerceros = data['referencia-terceros'];
            this.setItem(env.production ? `dev:${this.storageKeys.loanNPE}:${loan.numeroPrestam}` : `prod:${this.storageKeys.loanNPE}:${loan.numeroPrestam}`, JSON.stringify(data));
          }
        }).catch(error => {
          // console.warn(error);
          // 'Problemas de conexión: LOAH'
          this.utilService.presentToast('Error al obtener NPE.');
        });
      }
    });
  }

  getLoanNPE(loan) {
    return this.getItem(env.production ? `dev:${this.storageKeys.loanNPE}:${loan.numeroPrestam}` : `prod:${this.storageKeys.loanNPE}:${loan.numeroPrestam}`) || null;
  }

  getUserCurrentVehicleObservable() {
    return this.$userCurrentVehicle.asObservable();
  }

  pokeUserCurrentVehicle(data) {
    this.$userCurrentVehicle.next(data);
  }

  setUserCurrentVehicle(userVehicle, slideIndex) {
    this.userCrediQCurrentVehicle = userVehicle;
    this.userCrediQCurrentVehicleIndex = slideIndex;
    // window.localStorage.setItem('userVehicles', JSON.stringify(userVehicles));
  }

  getUserCurrentVehicle(): QCar | null {
    return this.userCrediQCurrentVehicle && this.userCrediQCurrentVehicle || this.getUserLoans() && this.getUserLoans().detalleUS[0] || null;
  }

  getUserCurrentLoan(car: QCar = null) {
    // return this.getUserLoans() && this.getUserLoans().detalleUS[this.getUserCurrentVehicleIndex()] || null;
    return this.getUserLoans() && this.getUserLoans().detalleUS.find(x => x.numeroChasis == (car && car.VIN || this.getUserCurrentVehicle().VIN)) || null;
  }

  getUserCurrentVehicleIndex(): number {
    return this.userCrediQCurrentVehicleIndex && this.userCrediQCurrentVehicleIndex || 0;
  }

  mergeVehiclesApp() {
    const migqVehicles: QCar[] = this.promisesService.getUserVehicles();
    let migqVehiclesMapped = [];
    const crediQloans = this.getUserLoans();
    let crediQVehicles: QCar[] = this.getMappedVehiclesLight();

    migqVehicles.map(gq => migqVehiclesMapped.push({...gq, ...{isGQ: true}}));

    // console.log('crediq vehicles');
    // console.log(crediQloans);

    // console.log('result');
    // console.log([...migqVehiclesMapped, ...crediQVehicles, ...migqVehicles]);

    const mergedArr = [...migqVehiclesMapped, ...crediQVehicles];

    const newArray = new Map();

    mergedArr.forEach((item: QCar) => {
      const propertyValue = item.VIN;
      newArray.has(propertyValue) ? newArray.set(propertyValue, {...item, ...newArray.get(propertyValue)}) : newArray.set(propertyValue, item);
    });

    const resultArray = Array.from(newArray.values());

    // console.log('------------------------');
    // console.log(resultArray);

    return resultArray;
  }

  mergeVehiclesLightApp() {
    const migqVehicles: QCar[] = this.promisesService.getUserVehicles();
    let crediQVehicles: QCar[] = this.getMappedVehiclesLight();


    const mergedArr = [...migqVehicles, ...crediQVehicles];

    const newArray = new Map();

    mergedArr.forEach((item: QCar) => {
      const propertyValue = item.VIN;
      newArray.has(propertyValue) ? newArray.set(propertyValue, {...item, ...newArray.get(propertyValue)}) : newArray.set(propertyValue, item);
    });

    const resultArray = Array.from(newArray.values());

    return resultArray;
  }

  addCreditCardWS(args) {
    return this.loanService.addUserCard(args);
  }

  changeLoanAliasWS(args) {
    return this.loanService.changeLoanAlias(args);
  }
}
