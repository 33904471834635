import {Injectable} from '@angular/core';
import {BaseService} from '../../services/http/base.service';
import {environment as env} from '../../../environments/environment';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class SmartService {

  private user = env.smartq.credentials.user;
  private passwd = env.smartq.credentials.passwd;
  private country = env.smartq.credentials.pais;

  constructor(
    private authService: AuthService,
    private bs: BaseService) {
  }

  login() {
    return this.bs.post([`${env.smartq.endpoints.login}`.replace(/\[cr\]/gi, 'cr')], {
      usuario: this.user,
      pass: this.passwd,
      pais: this.country
    });
  }

  cleanSafePropsFromStorage() {
    Object.entries(localStorage).map(
      x => x[0]
    ).filter(
      x => x.split(':').length > 1 && x.split(':')[1].substring(0, 4) === 'TMP_'
    ).map(
      x => localStorage.removeItem(x));
  }

  getPerformance(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.consumo}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin}, true);
  }

  getVelocityLimit(vehicle, details) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.limiteVelocidad}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin, detalle: details}, true);
  }

  getRoadHistory(vehicle, startDateTime, endDateTime) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.obtenerRuta}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      fechaIni: startDateTime,
      fechaFin: endDateTime
    }, true);
  }

  getlastPoint(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.ultimoPunto}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin}, true);
  }

  getAlertsHistory(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.alertasHistorial}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin}, true);
  }

  getAlertTypes(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.catalogoAlertas}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin}, true);
  }

  activateAlert(vehicle, alertType) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.activarAlerta}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin, alertas: alertType}, true);
  }

  deactivateAlert(vehicle, alertType) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.desactivarAlerta}`.replace(/\[cr\]/gi, country)], {usuario: this.user, vin, alertas: alertType}, true);
  }

  enviarComando(command, vehicle, details) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    console.log(details);
    return this.bs.post([`${env.smartq.endpoints.enviarComandos}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      comando: command,
      app: 'SMARTQ',
      detalle: details
    }, true);
  }

  setSpeedLimit(limit, vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.setLimiteVelocidad}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      velocidad: limit,
      // tiempoValidez: 80,
      // intervaloEnvio: 300,
      app: 'SMARTQ'
    }, true);
  }

}
