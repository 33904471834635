import {Injectable} from '@angular/core';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {AuthService} from './auth.service';

declare let gtag: any;

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  static OPEN_LOGIN = 'open_login';
  static OPEN_EMERGENCY = 'open_emergency';
  static OPEN_MEMBERSHIP = 'open_membership';
  static OPEN_CONTACT = 'open_contact';
  static OPEN_APPOINTMENT = 'open_appt';
  static OPEN_HELP = 'open_help';
  static OPEN_APPT_HISTORY = 'open_appt_history';
  static OPEN_APPT_RESCHEDULE = 'open_appt_reschedule_form';
  static OPEN_PROFILE = 'open_profile';
  static COMPLETE_APPOINTMENT = 'complete_appt';

  constructor(
    private authService: AuthService,
    private firebasex: FirebaseX,
  ) {
  }

  logEvent(event, page) {
    console.log('log event');
    console.log(event);
    if (this.authService.bs.isDesktop()) {
      console.log('from desktop');
      gtag('event', event, {
        event_category: page,
        event_label: event,
        value: this.authService.getProfile() && this.authService.getProfile().id_cliente || ''
      });
      return;
    }

    this.firebasex
      .logEvent(event, {
        page,
      })
      .then((res) => console.log(res))
      .catch((error) => console.error(error));
  }

}
