import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../providers/auth.service';
import {UtilService} from '../../../providers/util.service';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {AyudaService} from '../../../providers/ayuda.service';
import {Subscription} from 'rxjs';
import {Device} from '@ionic-native/device/ngx';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent implements OnInit, OnDestroy {
  usuario: any;
  comentario: any;
  sugerenciaLoading: any;
  backBtnSubscriber: Subscription;
  isSendingRequest = false;
  logo: string = "./assets/imgs/logoq-line.png";
  constructor(
    public modalCtrl: ModalController,
    public authService: AuthService,
    public utilService: UtilService,
    public alertCtrl: AlertController,
    private ayudaService: AyudaService,
    private platform: Platform,
    private navCtrl: NavController,
    private device: Device
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  initPage() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      if (!this.isSendingRequest) {
        return this.modalCtrl.dismiss();
      }
    });
  }

  reportarFalla() {
    if (this.comentario == undefined || this.comentario == '') {
      this.utilService.presentToast('Falla y comentario requeridos');
    } else {
      this.procesoReportarFalla();
    }
  }

  procesoReportarFalla() {
    let deviceCompleto = 'Web/Pruebas';

    const data = {
      tipo_mensaje: 'sugerencia',
      id_cliente: this.usuario.codigo,
      sociedad: this.usuario.sociedad,
      asunto: 'sugerencia',
      pais: this.usuario.pais,
      segmento: this.usuario.segmento,
      mensaje: this.utilService.cleanSpecialChars(this.comentario),
      version: environment.APP_VERSION,
      version_code: environment.APP_VERSION,
      device: deviceCompleto
    };
    // console.log('sug');
    // console.log(data);
    this.utilService.presentLoading();
    this.isSendingRequest = true;
    this.ayudaService.enviarFalla(data)
      .then(async (result) => {
        this.comentario = '';
        //this.presentToast(result);
        this.isSendingRequest = false;
        this.utilService.dismissLoading();
        const confirm = await this.alertCtrl.create({
          header: 'Aviso',
          message: result,
          cssClass: 'buttonCss',
          buttons: [
            {
              text: 'Aceptar'
            }
          ]
        });
        await confirm.present();
      }).catch(error => {
      console.warn(error);
      this.isSendingRequest = false;
      this.utilService.dismissLoading();
      this.utilService.presentToast('Problemas de conexión: SGS');
    });
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  goBack(){
    this.modalCtrl.dismiss();
  }

}
