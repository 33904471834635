const BASE_CREDIQ_URL = 'https://crediqweb.com:8181/CustomerBackServicesQA/webresources/';
const BASE_SMARTQ_URL = 'https://[cr].detektorgps.com/gps_[cr]/framework/servicios/wSmartQv/';
const BASE_GRAPHLQ_ENDPOINT = 'https://admin.gq.cronos.mobi/graphql';
const BASE_ENDPOINT_HTTP = 'https://admin.gq.cronos.mobi/';

export const environment = {
  production: false,
  SITE_URL: 'https://cronos-cdntest.nyc3.cdn.digitaloceanspaces.com/grupoq/development/',
  API_URLS: [
    ''
  ],
  // production: true,
  passcode: 'l0v32020@',
  settings: {
    key_current_theme: 'CURR_THEME',
    map_api: 'AIzaSyCrvUVLjfkK04rzVHVyDHmx6Ry-24qZEVM',
    key_push: '',
    key_session_storage: '',
    api_key: ''
  },
  endpoints: {
    SEND: '',
    endpointLoginClient: `${BASE_ENDPOINT_HTTP}/api/user/login/`,
    endpointDevice: `${BASE_ENDPOINT_HTTP}/api/user/device/`,
    endpointSendNotification: `${BASE_ENDPOINT_HTTP}/api/get_coupon/`,
  },
  endpointsCoupons: {
    endpointUserCoupons: `${BASE_ENDPOINT_HTTP}/api/userapp/coupons/`
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCuzoDRcwd6XVYTRsfwT3Uc--y-glBv4Tc',
    authDomain: 'migrupoq-c1974.firebaseapp.com',
    databaseURL: 'https://migrupoq-c1974.firebaseio.com',
    projectId: 'migrupoq-c1974',
    storageBucket: 'migrupoq-c1974.appspot.com',
    messagingSenderId: '397608709661',
    appId: '1:397608709661:web:d5065874b664b5e1b0e0f4'
  },
  // API_ENDPOINT: 'https://www.grupoq.co.cr/webservices/migrupoq_app/servicio.php',
  API_ENDPOINT: 'https://calidad.grupoq.co.cr/webservices/migrupoq_app/servicio.php',
  API_ENDPOINT_PROD: 'https://grupoq.co.cr/webservices/migrupoq_app/servicio.php',
  API_REST_ENDPOINT: 'https://pidev.grupoq.com:51501/RESTAdapter/smartq/migrupoq/services',
  API_GRAPHQL_ENDPOINT: `${BASE_GRAPHLQ_ENDPOINT}`,
  WS_CODIGO: 'e347d058dfa2834248f2455b418cd31a',
  APP_VERSION: 'MIGQ-CQ-SQ-BF-5.0.1-80 - QA',
  firebase: {
    user: 'soporte@cronos.mobi',
    password: ')xDo$rep('
  },
  crediq: {
    endpoints: {
      // login: `${BASE_CREDIQ_URL}cuentas/consultaCliente`,
      login: `${BASE_CREDIQ_URL}cuentas/registerSpecial`,
      getCards: `${BASE_CREDIQ_URL}tarjeta/tarjetasCliente`,
      loanDetail: `${BASE_CREDIQ_URL}prestamos/consultarPrestamos`,
      loanDetailLight: `${BASE_CREDIQ_URL}prestamos/getGarantias`,
      loanNPE: `${BASE_CREDIQ_URL}prestamos/carnetsAsociadosByPrestamo`,
      addCreditCard: `${BASE_CREDIQ_URL}tarjeta/agregarTarjeta`,
      changeLoanAlias: `${BASE_CREDIQ_URL}prestamos/actualizarPrestamo`,
      collecters: `${BASE_CREDIQ_URL}prestamos/colectores`,
      loanDetailRequest: `${BASE_CREDIQ_URL}prestamos/solicitarEstadoCuenta`,
    },
    storagekeys: {
      userCardsKey: 'crediq:userCards',
      userLoansDetailKey: 'crediq:userLoansDetail',
      userLoansDetailLightKey: 'crediq:userLoansDetailLight',
      loanNPE: 'crediq:npe',
      collecters: 'crediq:collecters',
    }
  },
  smartq: {
    credentials: {
      user: 'GRUPOQDTK',
      passwd: 'Q3JlZGlxMTIw',
      pais: 'CR'
    },
    endpoints: {
      login: `${BASE_SMARTQ_URL}generarToken`,
      limiteVelocidad: `${BASE_SMARTQ_URL}obtenerLimiteVelocidad`,
      setLimiteVelocidad: `${BASE_SMARTQ_URL}configurarVelocidad`,
      enviarComandos: `${BASE_SMARTQ_URL}enviarComando`,
      obtenerRuta: `${BASE_SMARTQ_URL}obtenerRuta`,
      ultimoPunto: `${BASE_SMARTQ_URL}ultimoPunto`,
      consumo: `${BASE_SMARTQ_URL}consumoCombustible`,
      obtenerAlertasConfiguradas: `${BASE_SMARTQ_URL}obtenerAlertas`,
      catalogoAlertas: `${BASE_SMARTQ_URL}obtenerAlertas`,
      catalogoAlertas2: `${BASE_SMARTQ_URL}obtenerAlertasConfigurables`,
      activarAlerta: `${BASE_SMARTQ_URL}activarAlertas`,
      desactivarAlerta: `${BASE_SMARTQ_URL}desactivarAlertas`,
      alertasHistorial: `${BASE_SMARTQ_URL}historialAlertasPlaca`
    },
    storagekeys: {
      smartToken: 'smartq:token',
      vehicleFlag: 'isSmart',
      performance: 'TMP_performance',
      speedLimit: 'TMP_speedLimit',
      alertHistory: 'TMP_alerts:history'
    }
  },
  cronos: {
    login: `${BASE_ENDPOINT_HTTP}api/user/login/`,
    addFavorite: `${BASE_ENDPOINT_HTTP}api/user/favorite/add/`,
    removeFavorite: `${BASE_ENDPOINT_HTTP}api/user/favorite/remove/`,
    updateFbID: `${BASE_ENDPOINT_HTTP}api/user/fbid/update/`,
    fbLogin: `${BASE_ENDPOINT_HTTP}api/user/login/fb/`
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
