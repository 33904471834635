import {onError} from "@apollo/client/link/error";

export function errorHandler(utilService) {
    return onError(({
       graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(
            ({ message,
               locations,
               path }) => {
                utilService.presentToast('Error, intente nuevamente');
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )}
          );
        }

        if (networkError) {
          utilService.presentToast('Revise su conexión a internet');
        }
  })
}
