import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { SkeletonComponent } from "../skeleton/skeleton.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImagePreloadDirective } from "../../directives/ImagePreload";
import { LoanDetailPopoverComponent } from "../loan-detail-popover/loan-detail-popover.component";
import { PaymentCardPopoverComponent } from "../payment-card-popover/payment-card-popover.component";
import { FaqComponent } from "../../pages/help/faq/faq.component";
import { BugsComponent } from "../../pages/help/bugs/bugs.component";
import { SuggestionsComponent } from "../../pages/help/suggestions/suggestions.component";
import { TermsComponent } from "../../pages/help/terms/terms.component";
import { NgxQRCodeModule } from "ngx-qrcode2";
import { AnimatedDigitComponent } from "../animated-digit/animated-digit";

@NgModule({
  declarations: [
    SkeletonComponent,
    ImagePreloadDirective,
    LoanDetailPopoverComponent,
    PaymentCardPopoverComponent,
    FaqComponent,
    BugsComponent,
    SuggestionsComponent,
    TermsComponent,
    AnimatedDigitComponent,
  ],
  entryComponents: [
    LoanDetailPopoverComponent,
    PaymentCardPopoverComponent,
    AnimatedDigitComponent,
    FaqComponent,
    BugsComponent,
    SuggestionsComponent,
    TermsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgxQRCodeModule,
  ],
  exports: [
    SkeletonComponent,
    ImagePreloadDirective,
    LoanDetailPopoverComponent,
    PaymentCardPopoverComponent,
    FaqComponent,
    BugsComponent,
    SuggestionsComponent,
    TermsComponent,
    AnimatedDigitComponent,
  ],
})
export class CommonComponentsModule {}
