<div class="ion-text-center ion-padding pop-header fs-RAJ">
  <ion-text>CARNÉ DE PAGO</ion-text>
</div>
<ion-list lines="none">

  <div class="ion-padding ion-text-center">
    <ion-text>SELECCIÓN DEL COLECTOR</ion-text>
  </div>

  <ion-item>
    <ion-label position="stacked">Punto de pago:</ion-label>
    <ion-select [(ngModel)]="selectedCollector" [value]="selectedCollector" cancelText="Cancelar" okText="Aceptar">
      <ion-select-option [value]="-1" disabled>Colectores</ion-select-option>
      <ion-select-option *ngFor="let collector of collecters"
                         [value]="collector">{{collector.nombre}}</ion-select-option>
    </ion-select>
  </ion-item>

  <!--Vehicle info-->
  <ion-grid>
    <div style="min-height: 200px;">
      <div class="ion-padding-horizontal ion-padding-top ion-text-center">
        <ion-text>
          <span>N° Préstamo: {{currentLoan?.numeroPrestam}}</span>
        </ion-text>
      </div>
      <ion-row class="ion-margin-top" [hidden]="selectedCollector?.tipoCarnet != 'QR'">
        <ion-col class="ion-no-padding">
          <ion-item lines="none">
            <ngx-qrcode style="width: 100%;" class="fullcode" [value]="npeConfig?.numero">
            </ngx-qrcode>
<!--            <img src="https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024.jpg" alt="qr">-->
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin-top" [hidden]="selectedCollector?.tipoCarnet != 'REF' || selectedCollector == -1">
        <ion-col class="ion-no-padding">
          <ion-item lines="none" class="ion-text-center">
            <h4 class="m-auto">{{npeConfig?.referenciaTerceros}}</h4>
          </ion-item>
          <ion-item lines="none" class="ion-text-center">
            <ion-text class="m-auto">PUNTO DE PAGO: {{selectedCollector?.nombre}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-margin-top" [hidden]="selectedCollector?.tipoCarnet != 'NPE' || selectedCollector == -1">
        <ion-col size="12">
          <div class="ion-text-center">
            <ion-text>NPE</ion-text>
          </div>
        </ion-col>
        <ion-col class="ion-no-padding">
          <ion-item lines="none" class="ion-text-center">
            <h4 class="m-auto">{{npeConfig?.npe}}</h4>
          </ion-item>
          <ion-item lines="none" class="ion-text-center">
            <ion-text class="m-auto">PUNTO DE PAGO: {{selectedCollector?.nombre}}</ion-text>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-list>

<div class="m-auto ion-text-center" style="width: 150px;">
  <div class="btn-ptm ion-margin-bottom cursor-pointer" (click)="dismissModal()">
    <ion-text color="light" class="cursor-pointer">Aceptar</ion-text>
  </div>
</div>
