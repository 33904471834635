<ion-content>
  <ion-fab *ngIf="showShareBtn" vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button>
      <ion-icon class="icon-share" name="share-social-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button (click)="copyToClipboard()">
        <ion-icon class="icon-sharing" name="link-outline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button *ngIf="!isDesktop" color="success" (click)="shareLocationWhatsApp($event)">
        <ion-icon class="icon-sharing" name="logo-whatsapp"></ion-icon>
      </ion-fab-button>
      <ion-fab-button *ngIf="!isDesktop" color="primary" (click)="shareLocationFacebook($event)">
        <ion-icon class="icon-sharing" name="logo-facebook"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  <app-header-car [backFn]="backFn" [allowCarSelection]="false"></app-header-car>
  <div #mapCanvas class="map-canvas"></div>
</ion-content>
