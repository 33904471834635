import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {Observable, Subject, Subscription} from 'rxjs';
import { LoanHelper } from '../helpers/crediq/LoanHelper';
import { VehicleHelper } from '../helpers/VehicleHelper';
import { AuthService } from './auth.service';
import { LoanService } from './crediq/loan.service';
import { PromisesService } from './promises.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  // TODO: Change with grupoQ link
  URL_MEDIA = 'http://clubclaro.com.ni/media/';

  constructor(
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController
  ) {
  }

  public spinner: any;
  private isSpinnerVisible = false;
  private spinnerBehavior = new Subject<any>();
  private spinnerSubscription: Subscription;


  static groupBy(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item
        ],
      }),
      {},
    );
  }

  public getObservableSpinner(): Observable<any> {
    return this.spinnerBehavior.asObservable();
  }

  async presentLoading() {
    this.spinner = await this.loadingCtrl.create({
      spinner: null,
      id: 'customSpinner',
      translucent: true,
      cssClass: 'migq-spinner',
      message: `<img src="./assets/imgs/loader/preloader.gif" alt="loading">`,
      backdropDismiss: false
    });

    return await this.spinner.present();
  }

  async dismissLoading() {
    if (this.spinner) {
      return this.spinner.dismiss();
    }
    return await this.loadingCtrl.dismiss(null, null, 'customSpinner').then(() => {
    });
  }

  async presentToast(mensaje) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 3000,
      position: 'bottom'
    });
    await toast.present();
  }

  async presentAlert(props) {
    const confirm = await this.alertCtrl.create({
      header: props.title || 'Aviso',
      message: props.message,
      cssClass: 'buttonCss',
      buttons: [
        {
          text: 'Aceptar'
        }
      ]
    });
    await confirm.present();
  }

  cleanSpecialChars(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/'/g, '');
  }

  showThumb() {
    // TODO: change with real datasource
    return this.URL_MEDIA;
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    if (lat2 > 0 || lon2 > 0) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d.toFixed(2);
    }else{
      // console.log("No se han encontrado GEOLOCATIONS");
    }
  }


  orderBusinessByDistance(businessList, currentPos) {
    let newList = [];
    businessList.forEach((business) => {
      const pos = {
        lat: business.latitude,
        lon: business.longitude,
      };

      const distance = this.getDistanceFromLatLonInKm(pos.lat, pos.lon, currentPos.lat, currentPos.lon);
      newList.push({...business, ...{distance}});
    });

    return this.sortByKey(newList, 'distance');
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  sortByKey(array, key) {
    return array.sort((a, b) => {
      return a[key] - b[key];
    });
  }
}
