import StorageHelper from './StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {QCar} from '../models/global';
import {environment} from '../../environments/environment';
import {VehiculosService} from '../providers/vehiculos.service';
import {CatalogService} from '../providers/catalog.service';
import {PromisesService} from '../providers/promises.service';
import {ApptHistoryHelper} from './ApptHistoryHelper';


@Injectable({
  providedIn: 'root'
})


export class VehicleHelper extends StorageHelper {

  $sliderVehicles = new Subject<any>();
  $deleteVehicle = new Subject<any>();
  $currentVehicle = new Subject<any>();
  currentVehicle: any;

  constructor(
    private apptHistoryHelper: ApptHistoryHelper,
    private vehicleService: VehiculosService,
    private catalogService: CatalogService,
    private promisesProvider: PromisesService,
  ) {
    super();
  }

  pokeVehicles(data) {
    this.$sliderVehicles.next(data);
  }

  pokeDeleteVehicle(data) {
    this.$deleteVehicle.next(data);
  }

  getDeleteVehicleAsObservable() {
    return this.$deleteVehicle.asObservable();
  }

  getVehiclesAsObservable() {
    return this.$sliderVehicles.asObservable();
  }

  getCurrentVehicleAsObservable() {
    return this.$currentVehicle.asObservable();
  }

  pokeCurrentVehicle(data) {
    this.$currentVehicle.next(data);
  }

  public getCurrentVehicle(): QCar {
    const vehicle = this.currentVehicle || JSON.parse(window.localStorage.getItem('currentGlobalVehicle'));
    return typeof vehicle === 'string' ? JSON.parse(vehicle) : vehicle;
  }

  public isCurrentVehicleCQ(): boolean {
    return this.getCurrentVehicle() && this.getCurrentVehicle().isCQ || false;
  }

  public setCurrentVehicle(vehicle) {
    this.currentVehicle = vehicle;
    window.localStorage.setItem('currentGlobalVehicle', JSON.stringify(vehicle));
  }


  async getUserVehicles() {
    this.vehicleService.getVehiculosCliente(this.apptHistoryHelper.authService.getSociedadUsuario(), this.apptHistoryHelper.authService.getCodigoUsuario())
      .then(async (result) => {
        // console.log('carros');
        // console.log(result);

        // this.vehiculos = result;
        var d = new Date();
        var dia = d.getDate();
        // localStorage.removeItem('vehiculos');
        localStorage.removeItem('fechaDeCarga');
        // localStorage.setItem('vehiculos', JSON.stringify(this.vehiculos));
        localStorage.setItem('fechaDeCarga', dia.toString());
        // this.vehiculos.forEach(vehiculo => {
        //   this.validarAseguradora(vehiculo);
        // });

        const storedVehicles = this.promisesProvider.getUserVehicles();
        this.promisesProvider.setUserVehiclesBase(result);

        // console.log('Checking for load history');
        // Preload history for first vehicle
        if (result && result.length > 0) {
          this.promisesProvider.setUserCurrentVehicle(storedVehicles[0], 0);
          this.setCurrentVehicle(storedVehicles[0]);
          this.pokeCurrentVehicle(storedVehicles[0]);
          this.apptHistoryHelper.loadCarHistory(result[0]);
        }

        // console.log('comparando datos viejos con nuevos');
        if (!storedVehicles || JSON.stringify(storedVehicles) != JSON.stringify(result) && Array.isArray(result)) {
          this.promisesProvider.setUserVehicles(result);
          this.promisesProvider.setUserCurrentVehicle(result[0], 0);
          this.setCurrentVehicle(result[0]);
          this.pokeCurrentVehicle(result[0]);
          this.promisesProvider.pokeUserVehicles(result);
        }

        // PRE-LOAD ALL INSURANCE COMPANIES FOR EACH SOCIETY (Using cache helper)
        Object.keys(this.apptHistoryHelper.authService.getSocieties()).map((society) => {
          this.cargarAseguradora(society); // Cache helper method
        });

        // GONNA LOAD INSURANCE COMPANIES
        this.catalogService.cargarAseguradoras(result);

      }).catch(error => {
      if (!environment.production) {
        // this.utilService.presentAlert({message: error});
      }
      // console.warn(error);
      // this.utilService.presentToast('Sin vehiculos asociados');
    });
  }


  cargarAseguradora(sociedad) {
    if (!this.isValidKeyETA(`aseguradoras:${sociedad}`, 30)) {
      return this.catalogService.cargarAseguradoras(sociedad)
        .then(result => {
          // console.log('aseguradoras para sociedad: ' +  sociedad);
          // console.log(result);
          localStorage.setItem(`aseguradoras:${sociedad}`, JSON.stringify(result));
        }).catch(error => {
          console.log(error);
          this.apptHistoryHelper.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
    // console.log('cantones aun validos');
  }


}
