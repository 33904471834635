import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AlertController } from "@ionic/angular";
import { BenefitHelper } from "../benefits/BenefitHelper";

@Injectable({
    providedIn: "root",
})
export class WorkshopsHelper {
    /* */
    $currentCountry = new Subject<any>();

    public selectedCountry = "SV";

    constructor(private alertCtrl: AlertController, private benefitHelper: BenefitHelper) {
        this.pokeCurrentCountry(this.selectedCountry);
        this.selectedCountry = window.localStorage.getItem("paisVisita") || "SV";
        setTimeout(() => {
            this.acceptChanges();
        }, 700);
    }

    acceptChanges() {
        const country = localStorage.getItem("paisVisita");
        this.handler(country);
    }

    getCurrentCountryAsObservable() {
        return this.$currentCountry.asObservable();
    }

    pokeCurrentCountry(val: string) {
        this.$currentCountry.next(val);
    }

    getCountryCodeByName(countryName: string) {
        const countries = {
            salvador: 'SV',
            nicaragua: 'NI',
            honduras: 'HN',
            guatemala: 'GT',
            costa_rica: 'CR',
        };

        return countryName in countries ? countries[countryName] : 'SV';
    }

    handler(data: any) {
        this.selectedCountry = data;
        this.pokeCurrentCountry(data);
        this.benefitHelper.selectedCountry = data;
        this.benefitHelper.pokeCurrentCountry(data);
    }

    async presentAlertCheckbox() {
        const alert = await this.alertCtrl.create({
            cssClass: "my-custom-class",
            header: "Seleccionar País",
            inputs: [
                {
                    name: "GT",
                    type: "radio",
                    label: "Guatemala",
                    value: "GT",
                    checked: this.selectedCountry === "GT",
                },
                {
                    name: "SV",
                    type: "radio",
                    label: "El Salvador",
                    value: "SV",
                    checked: this.selectedCountry === "SV",
                },
                {
                    name: "HN",
                    type: "radio",
                    label: "Honduras",
                    value: "HN",
                    checked: this.selectedCountry === "HN",
                },
                {
                    name: "NI",
                    type: "radio",
                    label: "Nicaragua",
                    value: "NI",
                    checked: this.selectedCountry === "NI",
                },
                {
                    name: "CR",
                    type: "radio",
                    label: "Costa Rica",
                    value: "CR",
                    checked: this.selectedCountry === "CR",
                },
            ],
            buttons: [
                {
                    text: "Cancelar",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => { },
                },
                {
                    text: "Aceptar",
                    cssClass: "btnAceptarWorkshops",
                    handler: (data) => {
                        this.handler(data);
                    },
                },
            ],
        });

        await alert.present();
    }
}
