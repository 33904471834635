import {Injectable} from '@angular/core';
import {BaseService} from '../../services/http/base.service';
import {environment as env} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor(private bs: BaseService) {
  }

  getUserCards(country, email, clientCode) {
    return this.bs.get([`${env.crediq.endpoints.getCards}?pais=${country}&email=${email}&codigoCliente=${clientCode}`]);
  }

  // loginCrediQ(country, channel, userID) {
  //   return this.bs.get([`${env.crediq.endpoints.login}?pais=${country}&canal=${channel}&identificacion=${userID}`]);
  // }

  loginCrediQ(country, channel, userID, email, passwd) {
    return this.bs.get([`${env.crediq.endpoints.login}?pais=${country}&numeroIdentificacion=${userID}&email=${email}&pass=${passwd}&canal=MIGRUPOQ&version=4.8`]);
  }

  getLoanDetails(country, clientCode) {
    return this.bs.get([`${env.crediq.endpoints.loanDetail}?pais=${country}&codigoCliente=${clientCode}`]);
  }

  requestLoanDetail(country, clientCode, email, idPrest) {
    return this.bs.get([`${env.crediq.endpoints.loanDetailRequest}?pais=${country}&codigoCliente=${clientCode}&email=${email}&idPrest=${idPrest}&detalle=D`]);
  }

  getLoanDetailsLight(country, clientCode) {
    return this.bs.get([`${env.crediq.endpoints.loanDetailLight}?pais=${country}&codigoCliente=${clientCode}`]);
  }

  getLoanNPE(country, clientCode, loanNumber) {
    return this.bs.get([`${env.crediq.endpoints.loanNPE}?pais=${country}&codigoCliente=${clientCode}&numeroPrestamo=${loanNumber}`]);
  }

  addUserCard(args) {
    return this.bs.get([`${env.crediq.endpoints.addCreditCard}?pais=${args.country}
    &email=${args.email}&numeroDocumento=${args.doc}
    &anioVencimiento=${args.expYear}
    &mesVencimiento=${args.expMonth}
    &codigoSeguridad=${args.cvv}
    &nombreTitular=${args.cardName}
    &emisor=${args.cardCompany}
    &tipoTarjeta=${args.cardType}
    &alias=${args.cardAlias}`.replace(/ /g, '')]);
  }

  changeLoanAlias(args) {
    return this.bs.get([`${env.crediq.endpoints.changeLoanAlias}?pais=${args.country}&id=${args.loanNumber}&alias=${args.loanALias}`]);
  }

  

}
