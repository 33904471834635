import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { Input } from '@angular/core';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent implements OnInit, OnDestroy {
  logo: string = "./assets/imgs/logoq-line.png";
  backBtnSubscriber: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController,
    public authService: AuthService,
  ) {}

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  initPage() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(
      999999,
      () => {
        return this.modalCtrl.dismiss();
      }
    );
  }

  goBack(){
    this.modalCtrl.dismiss();
  }
}
