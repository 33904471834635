import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public authService: AuthService,
              private loadingCtrl: LoadingController,
              private router: Router) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {

    const data = await this.authService.getToken();

    if (data) {
      return true;
    } else {
      let url = 'login';

      if (this.authService.bs.isDesktop()) {
        url = 'landing';
        return this.authService.logout();
      }

      this.router.navigate([url], {});
      return false;
    }
  }
}

