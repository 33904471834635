import {gql} from "apollo-angular";

export const HIGHLIGHTS_QUERY = gql`
  query getHighlights($country: String!){
    higlightedBenefitsByCountry(country: $country) {
      id
      benefit
      business  {
        id
        business
        logoUrl
        facebookUrl
        instagramUrl
        twitterUrl
        whatsappUrl
        phoneNumber
        geolocation
      }
      header
      description
      benefitArtUrl
      bannerImage
      canShare
      business {
        id,
        logoUrl,
        facebookUrl,
        twitterUrl,
        whatsappUrl,
        instagramUrl,
        business,
        geolocation,
        phoneNumber
      }
      tags {
        name
      }
    }
  }`;
