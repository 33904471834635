<ion-content color="gray">
  <div class="ion-align-items-center bck-btn-cnt">
    <ion-col (click)="closeModal()" size="2" class="ion-text-center ion-no-padding">
      <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
    </ion-col>
  </div>
  <ion-grid class="ion-no-padding">
    <div [hidden]="!mostrarMembresia" class="p-relative" style="max-width: 450px; margin: auto;">
      <p class="gp-nombre-membresia">
        {{usuario?.nombre}}
        <br>
        {{usuario?.codigo}}
      </p>
      <img class="img-membresia" src="{{imgMembresia}}">
      <img class="img-codigo-barras" src="assets/imgs/membresias/ic_codigobarra_small.jpg" imageViewer>
    </div>
  </ion-grid>
</ion-content>