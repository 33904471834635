import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../providers/util.service';

@Injectable({
  providedIn: 'root'
})
export class BackbuttonService {

  _existeBackButtonCache: boolean = false;

  backRedirectionDefault: backButtonRedirectionHomeInterface = {
    index: {
      status: true
    },
    migq: {
      status: false
    },
    crediq: {
      status: false
    },
    smartq: {
      status: false
    },
    homebenefits: {
      status: false
    }
  }

  backRedirectionUpdate: backButtonRedirectionHomeInterface = {
    index: {
      status: false
    },
    migq: {
      status: false
    },
    crediq: {
      status: false
    },
    smartq: {
      status: false
    },
    homebenefits: {
      status: false
    }
  }


  constructor(private utilService: UtilService, private router: Router) {
    this.existBackRedirectionHome();
  }

  existBackRedirectionHome() {
    const existBackRedirection = !!localStorage.getItem("backRedirectionHome");
    if (existBackRedirection) {
      this._existeBackButtonCache = true;
    } else {
      this._existeBackButtonCache = false;
    }
  }

  setFalseTempUpdate() {
    this.backRedirectionUpdate.index.status = false;
    this.backRedirectionUpdate.migq.status = false;
    this.backRedirectionUpdate.crediq.status = false;
    this.backRedirectionUpdate.smartq.status = false;
    this.backRedirectionUpdate.homebenefits.status = false;
  }

  createBackRedirectionHome() {
    localStorage.setItem("backRedirectionHome", JSON.stringify(this.backRedirectionDefault));
  }

  updateBackRedirectionHome(pos: any, value: boolean) {
    this.existBackRedirectionHome();
    this.setFalseTempUpdate();
    if (this._existeBackButtonCache) {
      this.backRedirectionUpdate[pos].status = value;
      this.backRedirectionDefault = null;
      this.backRedirectionDefault = this.backRedirectionUpdate;
      this.createBackRedirectionHome();
    } else {
      this.createBackRedirectionHome();
    }
  }

  verifyBackRedirectionHome() {
    if (this._existeBackButtonCache) { } else {
      this.createBackRedirectionHome();
    }
  }

  execFunctionVerifyBackButtonCache() {
    this.existBackRedirectionHome();
    this.verifyBackRedirectionHome();
  }

  getBackRedirectionHome() {
    if (this._existeBackButtonCache) {
      this.redirect();
    } else {
      this.createBackRedirectionHome();
      this.redirect();
    }
  }

  redirect() {
    if (this.backRedirectionDefault.smartq.status) {
      this.router.navigate(["/smartq/home"]);
    } else if (this.backRedirectionDefault.migq.status) {
      this.router.navigate(["/grupoq/home"]);
    } else if (this.backRedirectionDefault.crediq.status) {
      this.router.navigate(["/crediq/home"]);
    } else if (this.backRedirectionDefault.homebenefits.status) {
      this.router.navigate(["/benefit-tabs/miscellaneous"]);
    } else {
      this.router.navigate(["/home/inbox"]);
    }
  }

}

interface backButtonRedirectionHomeInterface {
  index: {
    status: boolean;
  },
  migq: {
    status: boolean;
  },
  crediq: {
    status: boolean;
  },
  smartq: {
    status: boolean;
  },
  homebenefits: {
    status: boolean;
  }
}
